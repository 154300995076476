import { faCircleNotch, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import formatDate from 'helpers/formatDate';
import { Chronology } from 'types';

interface AddToChronologyModalContentProps {
  isLoadingChronologies: boolean;
  chronologies: Chronology[];
  handleSelectChronology: (chronologyId: string) => void;
}

const AddToChronologyModalContent = ({
  isLoadingChronologies,
  chronologies,
  handleSelectChronology,
}: AddToChronologyModalContentProps) => {
  return (
    <div className="flex h-96 flex-col items-center justify-start overflow-auto overflow-y-scroll p-4">
      {isLoadingChronologies ? (
        <div className="flex h-full items-center justify-center">
          <FontAwesomeIcon icon={faCircleNotch} className="fa-spin text-gray-600" />
        </div>
      ) : (
        <div className="w-full px-6">
          <p className="mb-4 border-b pb-1 text-sm text-gray-700">Select an existing chronology to add facts to.</p>
          <div className="flex w-full flex-col justify-between gap-4">
            {chronologies.map((chronology) => {
              return (
                <div
                  key={chronology.id}
                  className="flex items-center justify-between rounded-lg border bg-gray-50 px-4 py-2 text-sm shadow"
                >
                  <div className="flex flex-col">
                    <p className="font-medium">
                      {chronology.title.length > 40 ? chronology.title.slice(0, 40) + '...' : chronology.title}
                    </p>
                    <p className="text-xs text-gray-500">Created {formatDate(chronology.createdDate)}</p>
                  </div>
                  <button
                    className="rounded-lg bg-buttonPrimary px-2 py-1 text-xs text-white hover:bg-buttonPrimary-hover"
                    onClick={() => handleSelectChronology(chronology.id)}
                  >
                    <FontAwesomeIcon icon={faPlusCircle} className="mr-1" /> Add
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddToChronologyModalContent;
