import { Ref, useCallback, useRef } from 'react';

import {
  faCircleNotch,
  faListCheck,
  faMagnifyingGlass,
  faPlusCircle,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HighlightArea } from '@react-pdf-viewer/highlight';
import { trackCustomEvent } from 'analytics/Mixpanel';
import { ReactComponent as AskKim } from 'assets/icons/question-file.svg';
import Button from 'components/atoms/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import { FactWithHighlights } from 'types';

import DocViewerFact from '../DocViewerFact';

interface DocViewerFactSidebarProps {
  docId: string;
  factTotal: string | null;
  isLoading: boolean;
  jumpToHighlightArea: (area: HighlightArea) => void;
  highlightedFactId: string | null;
  setHighlightedFactId: (id: string) => void;
  selectFact: (fact: FactWithHighlights) => void;
  facts: FactWithHighlights[];
  listRef: Ref<List>;
  setFactAdderModalIsOpen: (bool: boolean) => void;
}

const DocViewerFactSidebar = ({
  docId,
  factTotal,
  jumpToHighlightArea,
  highlightedFactId,
  setHighlightedFactId,
  selectFact,
  facts,
  listRef,
  isLoading,
  setFactAdderModalIsOpen,
}: DocViewerFactSidebarProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleAskKim = () => {
    trackCustomEvent('Ask Kim From Doc Viewer');
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('selectedDocId', docId);
    navigate('/app/chronos/case-editor/assistant?' + searchParams.toString());
  };

  const Row = ({ index, style }: ListChildComponentProps) => {
    const handleJumpToHighlightArea = useCallback(
      (fact: FactWithHighlights) => {
        if (fact.highlights && fact.highlights.length > 0) {
          jumpToHighlightArea(fact.highlights[0]);
        }
        setHighlightedFactId(fact.id);
      },
      // eslint-disable-next-line
      [jumpToHighlightArea],
    );

    const fact = facts[index];
    if (!fact) return null;

    return (
      <div style={style} className="flex items-center gap-2 py-2">
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          className="h-3 w-3 flex-shrink-0 cursor-pointer rounded-full border p-2 shadow hover:bg-gray-100"
          onClick={() => handleJumpToHighlightArea(fact)}
        />
        <div
          id={`fact-${fact.id}`}
          className={`flex-grow cursor-pointer rounded-md border bg-white shadow-sm transition-all duration-300 ease-in-out hover:bg-gray-100 ${
            highlightedFactId === fact.id ? 'border-brandSecondary' : ''
          }`}
          onClick={() => {
            trackCustomEvent('Click Jump to Fact From Facts List');
            selectFact(fact);
            handleJumpToHighlightArea(fact);
          }}
        >
          {<DocViewerFact fact={fact} />}
        </div>
      </div>
    );
  };

  return (
    <div className="flex w-5/12 flex-col border-l border-gray-300 bg-gray-50">
      <div className="flex w-full items-center justify-between border-b bg-white pb-2 pl-4 pt-3 font-medium shadow-sm">
        <div className="flex items-center gap-2">
          <FontAwesomeIcon icon={faListCheck} />
          Facts <span className="text-sm text-gray-500">{factTotal ? `(${factTotal})` : ''}</span>
        </div>
        <div className="flex items-center gap-2">
          <Button
            icon={<AskKim className="h-4 w-4" />}
            text="Ask Kim"
            onClick={handleAskKim}
            type="primary"
            size="small"
            className="flex items-center gap-2 rounded border bg-buttonSecondary px-2 py-2 text-xs text-gray-700 shadow hover:bg-buttonSecondary-hover"
          />
          <Button
            icon={<FontAwesomeIcon icon={faPlusCircle} className="pr-2" />}
            text="Add Fact"
            onClick={() => setFactAdderModalIsOpen(true)}
            type="primary"
            size="small"
            className="rounded border bg-buttonSecondary px-2 py-2 text-xs text-gray-700 shadow hover:bg-buttonSecondary-hover"
          />
        </div>
      </div>
      <div id="document-facts-container" className="flex-1 overflow-y-auto" ref={containerRef}>
        <div className="flex h-full flex-col gap-2 bg-gray-50 py-1 pb-3 pl-2 pr-1 pt-2">
          {isLoading ? (
            <div className="flex h-full items-center justify-center">
              <FontAwesomeIcon icon={faCircleNotch} className="fa-spin h-4 w-4" />
            </div>
          ) : facts.length > 0 ? (
            <AutoSizer>
              {({ height, width }: { height: number; width: number }) => (
                <List ref={listRef} height={height} itemCount={facts.length} itemSize={72} width={width}>
                  {Row}
                </List>
              )}
            </AutoSizer>
          ) : (
            <div className="flex h-full flex-col items-center justify-center gap-2">
              <FontAwesomeIcon icon={faQuestionCircle} className="h-6 w-6 text-gray-500" />
              <span className="text-sm text-gray-500">Could not find any facts in this document.</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DocViewerFactSidebar;
