import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  highlightPlugin,
  RenderHighlightContentProps,
  RenderHighlightsProps,
  RenderHighlightTargetProps,
  Trigger,
} from '@react-pdf-viewer/highlight';
import { trackCustomEvent, trackPageView } from 'analytics/Mixpanel';
import Button from 'components/atoms/Button';
import ToastDrawer from 'components/molecules/ToastDrawer';
import PDFViewer from 'components/organisms/PDFViewer';
import { motion } from 'framer-motion';
import { useLocation, useNavigate } from 'react-router-dom';
import { FixedSizeList as List } from 'react-window';
import { BoundingBox, Fact, FactWithHighlights, HighlightCoordinate, Location } from 'types';

import { useGetDocPresignedURL } from '../../../../../../api/queries/useGetDocPresignedURL';
import useFetchDocumentFacts from '../../../DataView/DocumentsEditor/hooks/useFetchDocumentFacts';
import FactAdderModal from '../../Modals/FactAdderModal';
import DocViewerFactSidebar from '../components/DocViewerFactSidebar';
import DocViewerFullFact from '../components/DocViewerFullFact';

export interface FactPosition {
  pageIndex: number;
  top: number;
  coordinates: HighlightCoordinate;
  index?: number;
}

const DocViewer = ({
  caseId,
  docId,
  pageNumber,
  factTotal,
  title,
}: {
  caseId: string;
  docId: string;
  pageNumber: number;
  factTotal: string | null;
  title?: string;
}) => {
  // State
  const navigate = useNavigate();
  const location = useLocation();

  const viewerRef = useRef(null);
  const listRef = useRef<List>(null);

  const [facts, setFacts] = useState<Fact[]>([]);
  const [highlightCoordinates, setHighlightCoordinates] = useState<HighlightCoordinate[]>([]);
  const [highlightedFactId, setHighlightedFactId] = useState<string | null>(null);
  const [selectedFact, setSelectedFact] = useState<FactWithHighlights | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [factAdderModalIsOpen, setFactAdderModalIsOpen] = useState(false);

  // Data fetching -------------
  const { data: docUrl } = useGetDocPresignedURL(caseId, docId);
  const { data: responseFacts, isLoading, isFetching } = useFetchDocumentFacts({ docId, caseId });

  //------------------------------------------------------

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    setSelectedFact(null);
  };

  const selectFact = (fact: FactWithHighlights) => {
    setSelectedFact(fact);
    setIsDrawerOpen(true);
  };

  // Coordinate and fact processing
  const { sortedFacts, coordinates } = useMemo(() => {
    if (!responseFacts) return { sortedFacts: [], coordinates: [] };

    // Sort facts by the order at which they appear in the doc
    const sortedFacts: FactWithHighlights[] = [...responseFacts].sort((a: Fact, b: Fact) => {
      const aLocation = a.locations.find((location: Location) => location.docId === docId);
      const bLocation = b.locations.find((location: Location) => location.docId === docId);
      if (!aLocation || !bLocation) return 0;

      if (!aLocation.boundingBoxes?.length) return 1;
      if (!bLocation.boundingBoxes?.length) return -1;

      const pageCompare = aLocation.boundingBoxes[0].pageNumber - bLocation.boundingBoxes[0].pageNumber;
      if (pageCompare !== 0) return pageCompare;

      return aLocation.boundingBoxes[0].top - bLocation.boundingBoxes[0].top;
    });

    // Process coordinates
    const coordinates: HighlightCoordinate[] = [];
    sortedFacts.forEach((fact, index) => {
      fact.locations
        .filter((location) => location.docId === docId)
        .forEach((location) => {
          const highlights = location.boundingBoxes.map((boundingBox: BoundingBox) => ({
            index,
            id: fact.id,
            height: boundingBox.height * 100,
            left: boundingBox.left * 100,
            pageIndex: boundingBox.pageNumber - 1,
            top: boundingBox.top * 100,
            width: boundingBox.width * 100,
          }));

          fact.highlights = highlights;
          coordinates.push(...highlights);
        });
    });

    return { sortedFacts, coordinates };
  }, [responseFacts, docId]);

  useEffect(() => {
    setFacts(sortedFacts);
    setHighlightCoordinates(coordinates);
  }, [sortedFacts, coordinates]);

  // Event handlers
  const handleCloseDocDetailView = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('docId');
    searchParams.delete('docPageNumber');
    navigate(location.pathname + '?' + searchParams.toString(), { replace: true });
  }, [navigate, location.pathname, location.search]);

  const handleNewFactCreated = useCallback(() => {
    setFactAdderModalIsOpen(false);
    trackCustomEvent('New Fact Created From Doc Viewer', { caseId, docId });
    // eslint-disable-next-line
  }, []);

  // Highlight Handlers
  const scrollToFact = (index: number) => {
    if (listRef.current) {
      listRef.current.scrollToItem(index, 'center');
    }
  };

  const renderHighlights = (props: RenderHighlightsProps) => {
    return (
      <div>
        {highlightCoordinates
          .filter((area) => area.pageIndex === props.pageIndex)
          .map((area, idx) => (
            <div
              key={idx}
              className={`highlight-area ${
                area.id === highlightedFactId ? 'bg-brandSecondary' : 'bg-yellow-300'
              } z-10 cursor-pointer rounded-sm`}
              style={Object.assign({}, props.getCssProperties(area, props.rotation), {
                pointerEvents: 'auto',
                opacity: '0.3',
                mixBlendMode: 'darken',
              })}
              onClick={(e) => {
                e.stopPropagation();
                trackCustomEvent('Click Jump to Fact From PDF Viewer Highlight');
                setHighlightedFactId(area.id);
                scrollToFact(area.index);
              }}
            ></div>
          ))}
      </div>
    );
  };

  const renderHighlightTarget = (props: RenderHighlightTargetProps) => (
    <div
      style={{
        background: '#eee',
        display: 'flex',
        position: 'absolute',
        left: `${props.selectionRegion.left}%`,
        top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
        transform: 'translate(0, 8px)',
        zIndex: 10,
      }}
    >
      <Button
        icon={<FontAwesomeIcon icon={faPlusCircle} className="pr-2" />}
        className="rounded border bg-buttonSecondary px-2 py-2 text-xs shadow hover:bg-buttonSecondary-hover"
        onClick={props.toggle}
        text="Add Fact"
      />
    </div>
  );

  const renderHighlightContent = (props: RenderHighlightContentProps) => {
    const onSuccess = () => {
      trackCustomEvent('New Fact Created From Highlighting Doc Viewer', { caseId, docId });
      props.cancel();
    };

    return (
      <FactAdderModal
        caseId={caseId}
        docId={docId}
        onSuccess={onSuccess}
        selectedText={props.selectedText}
        onClose={props.cancel}
        highlightAreas={props.highlightAreas}
        isOpen
      />
    );
  };

  const highlightPluginAddFact = highlightPlugin({
    renderHighlightTarget,
    renderHighlightContent,
    trigger: Trigger.TextSelection,
  });

  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });
  const { jumpToHighlightArea } = highlightPluginInstance;

  trackPageView('Doc viewer');

  return (
    <div className="flex h-full">
      <div className="w-7/12 overflow-hidden">
        <div ref={viewerRef} className="h-full">
          <PDFViewer
            fileUrl={docUrl}
            highlightInstance={highlightPluginInstance}
            highlightToAddFactInstance={highlightPluginAddFact}
            initialPage={pageNumber ?? 1}
            handleClosePDFViewer={handleCloseDocDetailView}
            closeButtonText="Back"
            defaultZoom={1.1}
            type="full"
            title={title}
          />
        </div>
      </div>

      <DocViewerFactSidebar
        docId={docId}
        isLoading={isLoading || isFetching}
        factTotal={factTotal}
        jumpToHighlightArea={jumpToHighlightArea}
        highlightedFactId={highlightedFactId}
        setHighlightedFactId={setHighlightedFactId}
        selectFact={selectFact}
        facts={facts}
        listRef={listRef}
        setFactAdderModalIsOpen={setFactAdderModalIsOpen}
      />

      <ToastDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer}>
        {selectedFact && (
          <motion.div key={selectedFact.id}>
            <DocViewerFullFact fact={selectedFact} />
          </motion.div>
        )}
      </ToastDrawer>

      <FactAdderModal
        caseId={caseId}
        docId={docId}
        onSuccess={handleNewFactCreated}
        onClose={() => setFactAdderModalIsOpen(false)}
        isOpen={factAdderModalIsOpen}
      />
    </div>
  );
};

export default DocViewer;
