import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useMutation } from 'react-query';

export interface ApiError {
  statusCode: number;
  message: string;
}

const useVerifyChronologyEvent = (caseId: string) => {
  const { getFetchConfig } = useGetFetchConfig();
  const updateVerifiedMutation = useMutation(({ factId, verified }: { factId: string; verified: boolean }) => {
    const fetchConfig = getFetchConfig({
      method: 'PATCH',
      data: {
        verified: verified,
      },
    });

    if (verified) {
      return fetch(`${APIBaseChronos}/api/case/${caseId}/chronology/fact/${factId}/verify`, fetchConfig).then((res) =>
        res.json(),
      );
    } else {
      return fetch(`${APIBaseChronos}/api/case/${caseId}/chronology/fact/${factId}/unverify`, fetchConfig).then((res) =>
        res.json(),
      );
    }
  });

  return {
    updateVerifiedMutation,
  };
};

export default useVerifyChronologyEvent;
