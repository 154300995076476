import { useEffect, useState } from 'react';

import { RelevantFactsByDoc } from 'types';

const Doc = ({
  title,
  relevantFacts,
  top,
  onClick,
  delay,
}: {
  title: string;
  relevantFacts: number;
  delay: number;
  onClick: () => void;
  top?: boolean;
}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(true);
    }, delay);
    return () => clearTimeout(timeout);
  }, [delay]);

  const formatTitle = (title: string) => {
    if (title.length > 45) {
      return title.slice(0, 45) + '...';
    }
    return title;
  };

  return (
    <div
      className={`flex transform cursor-pointer justify-between gap-2 rounded border border-blue-600 border-opacity-40 px-3 py-3 shadow-sm transition-all duration-500 hover:scale-105 ${
        visible ? 'translate-y-0 opacity-100' : 'translate-y-8 opacity-0'
      } ${top ? 'bg-blue-50' : 'bg-white'}`}
      style={{ transitionDelay: `${delay}ms` }}
      onClick={onClick}
    >
      <p className="italic">{formatTitle(title)}</p>
      <p className="text-xs text-gray-500">
        <span className="text-sm font-semibold text-blue-500">{relevantFacts}</span> Relevant Facts
      </p>
    </div>
  );
};

const RelevantDocs = ({ docs, onClick }: { docs: RelevantFactsByDoc[]; onClick: (docId: string) => void }) => {
  return (
    <div className="flex flex-col gap-2">
      {docs.map((doc, index) => (
        <Doc
          key={index}
          title={doc.fileName}
          relevantFacts={doc.count}
          delay={index * 100}
          onClick={() => onClick(doc.documentId)}
          top={index === 0}
        />
      ))}
    </div>
  );
};

export default RelevantDocs;
