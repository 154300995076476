import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useMutation } from 'react-query';

interface ProcessCaseParams {
  caseId: string;
  legalIssues: string;
  name: string;
  type?: string;
  parties?: string;
  disputeStatus?: string;
  keyTimePeriod?: string;
  keyContext?: string;
}

export const useProcessCase = () => {
  const { getFetchConfig } = useGetFetchConfig();

  return useMutation(
    async ({
      caseId,
      name,
      type,
      legalIssues,
      parties,
      disputeStatus,
      keyTimePeriod,
      keyContext,
    }: ProcessCaseParams) => {
      const fetchConfig = getFetchConfig({
        method: 'POST',
        data: {
          name,
          type,
          legalIssues,
          parties,
          disputeStatus,
          keyTimePeriod,
          keyContext,
        },
      });

      const response = await fetch(`${APIBaseChronos}/api/case/${caseId}/submit`, fetchConfig);
      if (!response.ok) {
        throw new Error('Failed to submit case');
      }
    },
  );
};
