import React, { useState } from 'react';

import {
  faChartColumn,
  faDoorOpen,
  faEnvelope,
  faGear,
  faPhone,
  faBookOpen,
  IconDefinition,
  faHome,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLogoutFunction } from '@propelauth/react';
import { settingsHost } from 'api/authHosts';
import { useUserContext } from 'Contexts/User';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as Styled from './styles';

const SideMenu: React.FC = () => {
  const [compressed, setCompressed] = useState<boolean>(true);
  const logoutFn = useLogoutFunction();
  const { user } = useUserContext();

  const handleClickLogout = (): void => {
    logoutFn(true);
    toast.info('See you again soon.');
  };

  const handleClickSettings = (): void => {
    window.open(settingsHost, '_self');
  };

  interface NavItemProps {
    title: string;
    link: string;
    compressed: boolean;
    icon: IconDefinition;
  }

  const NavItem = ({ title, link, compressed, icon }: NavItemProps) => {
    return (
      <NavLink
        to={link}
        className={`mb-5 flex h-11 cursor-pointer flex-row items-center text-base font-normal not-italic leading-5 text-white ${
          !compressed ? 'w-60' : ''
        }`}
      >
        <FontAwesomeIcon icon={icon} className="mr-3 w-5 text-white" />
        {!compressed && title}
      </NavLink>
    );
  };

  return (
    <Styled.ContainerPanel compressed={compressed}>
      <Styled.ContainerMenu className="h-full w-full">
        <div
          className="mb-10 cursor-pointer text-3xl font-semibold not-italic text-white"
          onClick={() => setCompressed(!compressed)}
        >
          {compressed ? 'w' : 'wexler.ai'}
        </div>
        <>
          <NavItem title="Home" compressed={compressed} link="app/chronos/matters" icon={faHome} />
          <NavItem title="User Guide" compressed={compressed} link="app/chronos/guide" icon={faBookOpen} />
          {user?.metadata && user?.metadata?.metadata?.analyticsAdmin === 'true' && (
            <NavItem title="Usage" compressed={compressed} link="app/chronos/usage-dashboard" icon={faChartColumn} />
          )}
        </>
        <div className="mt-auto text-sm">
          <a
            href="mailto:support@wexler.ai"
            className="flex h-11 cursor-pointer flex-row items-center font-normal not-italic text-white"
          >
            <FontAwesomeIcon icon={faEnvelope} className="mr-3 w-5 text-white" />
            {!compressed && 'support@wexler.ai'}
          </a>
          <a
            href="tel:+442080409060"
            className={`flex h-11 cursor-pointer flex-row items-center font-normal not-italic text-white ${
              !compressed ? 'w-60' : ''
            }`}
          >
            <FontAwesomeIcon icon={faPhone} className="mr-3 w-5 text-white" />
            {!compressed && '+44 2080 4090 60'}
          </a>

          <div
            className={`flex h-11 cursor-pointer flex-row items-center font-normal not-italic text-white ${
              !compressed ? 'w-60' : ''
            }`}
            onClick={handleClickSettings}
          >
            <FontAwesomeIcon icon={faGear} className="mr-3 w-5 text-white" />
            {!compressed && 'Settings'}
          </div>
          <div
            className={`flex h-11 cursor-pointer flex-row items-center font-normal not-italic text-white ${
              !compressed ? 'w-60' : ''
            }`}
            onClick={handleClickLogout}
          >
            <FontAwesomeIcon icon={faDoorOpen} className="mr-3 w-5 text-white" />
            {!compressed && 'Logout'}
          </div>
        </div>
      </Styled.ContainerMenu>
    </Styled.ContainerPanel>
  );
};

export default React.memo(SideMenu);
