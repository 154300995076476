import { APIBaseChronos } from 'api/hosts';
import { QueryEntity } from 'api/types';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';

export const useFetchIncludedFactsCount = (caseId: string | null) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<number>([QueryEntity.IncludedFactsCount, { caseId }], () => {
    return fetch(`${APIBaseChronos}/api/case/${caseId}/facts/included/count`, fetchConfigGET)
      .then((res) => res.json())
      .catch((err) => {
        console.error(err);
      });
  });
};
