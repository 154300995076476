import { Classification, Relevance } from 'types';

export const RelevanceOptions = {
  relevant: {
    value: Relevance.YES,
    label: 'Yes',
  },
  notRelevant: {
    value: Relevance.NO,
    label: 'No',
  },
  maybeRelevant: {
    value: Relevance.MAYBE,
    label: 'Maybe',
  },
};

export const ClassificationOptions = {
  primary: {
    value: Classification.PRIMARY,
    label: 'Primary',
  },
  secondary: {
    value: Classification.SECONDARY,
    label: 'Secondary',
  },
  future: {
    value: Classification.FUTURE,
    label: 'Future',
  },
  litigation: {
    value: Classification.LITIGATION,
    label: 'Litigation',
  },
};

export const FilterOptions = {
  relevance: Object.values(RelevanceOptions),
  classification: Object.values(ClassificationOptions),
};
