import { Button } from 'components/atoms/new-button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'components/molecules/dropdown-menu';
import { PIPELINE_STATUS } from 'constants/pipelineStatus';
import formatDate from 'helpers/formatDate';
import { FileClock } from 'lucide-react';
import { Case } from 'types';

interface RunsOverviewProps {
  caseState: Case;
}

const RunsOverview = ({ caseState }: RunsOverviewProps) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button size={'sm'} className="border bg-white px-2 shadow-sm hover:cursor-pointer hover:bg-gray-100">
          <FileClock size={20} />
          Runs
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="mx-4 max-w-96 bg-white">
        <DropdownMenuLabel>Runs</DropdownMenuLabel>
        <DropdownMenuSeparator className="bg-gray-200" />
        <DropdownMenuGroup>
          <DropdownMenuItem className="grid w-full gap-4" style={{ gridTemplateColumns: '40px 1fr 1fr' }}>
            <span className="text-left font-semibold">Run</span>
            <span className="text-left font-semibold">Status</span>
            <span className="text-left font-semibold">Estimated End Time</span>
          </DropdownMenuItem>
          {caseState.runs?.map((run, index) => (
            <DropdownMenuItem
              key={run.id}
              className="grid w-full gap-4"
              style={{ gridTemplateColumns: '40px 1fr 1fr' }}
            >
              <span className="text-left">{index + 1}</span>
              <span className="text-left">
                {run.inQueue ? `In queue, position ${run.queuePosition}` : PIPELINE_STATUS[run.status]?.label}
              </span>
              <span className="text-left">{formatDate(run.completedAtEstimate, true)}</span>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default RunsOverview;
