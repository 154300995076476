import { APIBaseChronos } from 'api/hosts';
import { QueryEntity } from 'api/types';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';
import { DocIds } from 'types';

export const useGetDocIds = (caseId: string | null) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<DocIds[]>(
    [QueryEntity.DocIds],
    () =>
      fetch(`${APIBaseChronos}/api/case/${caseId}/doc-ids`, fetchConfigGET).then((res) => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.json();
      }),
    {
      enabled: !!caseId, // Ensure caseId is not null before fetching
    },
  );
};
