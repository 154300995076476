import { APIBaseChronos } from 'api/hosts'; // Ensure this path is correct
import { QueryEntity } from 'api/types';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';

import { GetMattersRes } from '../types/queries';

export interface QueryParams {
  search?: string;
  dateFrom?: string;
  dateTo?: string;
  sortBy?: string;
  page?: number;
  paginated?: string;
}

const useGetMatters = (queryParams: QueryParams) => {
  const { fetchConfigGET } = useGetFetchConfig();

  const queryString = new URLSearchParams(queryParams as Record<string, string>).toString();

  const fetchMatters = async (): Promise<GetMattersRes> => {
    const response = await fetch(`${APIBaseChronos}/api/matters?${queryString}`, fetchConfigGET);
    if (!response.ok) {
      throw new Error('Fetching matters failed');
    }
    return response.json();
  };

  return useQuery<GetMattersRes>([QueryEntity.Matter, queryString], fetchMatters, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

export default useGetMatters;
