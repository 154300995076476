import { useCallback, useEffect, useRef, useState } from 'react';

import { HighlightArea } from '@react-pdf-viewer/highlight';
import { trackPageView } from 'analytics/Mixpanel';
import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import PDFViewer from 'components/organisms/PDFViewer';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { Location } from 'types';

import { useGetDocPresignedURL } from '../../../../../../api/queries/useGetDocPresignedURL';

const DocViewer = ({
  caseId,
  docId,
  eventId,
  pageNumber,
}: {
  caseId: string;
  docId: string;
  eventId: string;
  pageNumber: number;
}) => {
  // State
  const navigate = useNavigate();
  const location = useLocation();

  const viewerRef = useRef(null);
  const [minPage, setMinPage] = useState(1);
  const [highlightCoordinates, setHighlightCoordinates] = useState<(HighlightArea & { factId: string })[]>([]);

  const { fetchConfigGET } = useGetFetchConfig();

  // Data fetching
  const { data: docUrl } = useGetDocPresignedURL(caseId, docId);

  async function getFactLocation(): Promise<Location | undefined> {
    const fact = await fetch(`${APIBaseChronos}/api/case/${caseId}/fact/${eventId}`, fetchConfigGET).then((res) => {
      return res.json();
    });

    return fact.locations?.find((loc: Location) => loc.docId === docId && loc.factId === eventId);
  }

  const { data: factLocation } = useQuery({
    queryKey: ['factLocation', docId, eventId],
    queryFn: getFactLocation,
  });

  // Event handlers
  const handleCloseDocDetailView = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('docId');
    searchParams.delete('eventId');
    searchParams.delete('docPageNumber');
    navigate(location.pathname + '?' + searchParams.toString(), { replace: true });
  }, [navigate, location.pathname, location.search]);

  useEffect(() => {
    if (docId && factLocation && factLocation.boundingBoxes.length > 0) {
      setMinPage(factLocation.boundingBoxes[0].pageNumber);
      // setDocuments(docIdsRelatedToEvent);

      const coordinates = factLocation.boundingBoxes.map((b) => ({
        width: b.width * 100,
        height: b.height * 100,
        top: b.top * 100,
        left: b.left * 100,
        pageIndex: b.pageNumber - 1,
        factId: eventId,
      }));
      setHighlightCoordinates(coordinates);
    }
  }, [factLocation, docId, eventId]);

  trackPageView('Doc viewer');

  return (
    <div className="flex h-full flex-col">
      <div className="flex-grow overflow-hidden">
        <div ref={viewerRef} className="h-full">
          <PDFViewer
            fileUrl={docUrl}
            initialPage={pageNumber || minPage}
            highlights={highlightCoordinates}
            handleClosePDFViewer={handleCloseDocDetailView}
            closeButtonText="Close"
            defaultZoom={0.8}
            type="inline"
          />
        </div>
      </div>
    </div>
  );
};

export default DocViewer;
