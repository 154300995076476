import { useMemo, useCallback } from 'react';

import Tabs from 'components/molecules/Tabs';
import { useNavigate, useLocation } from 'react-router-dom';

interface NavigationTabsProps {
  caseId: string;
}

const NavigationTabs = ({ caseId }: NavigationTabsProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentView = location.pathname.split('/').pop();

  const handleTabClick = useCallback(
    (path: string) => () => {
      navigate(path);
    },
    [navigate],
  );

  const tabItems = useMemo(
    () => [
      {
        label: 'Home',
        id: 'summary',
        onClick: handleTabClick(`/app/chronos/case-editor/summary?caseId=${caseId}`),
      },
      {
        label: 'Data',
        id: 'facts',
        onClick: handleTabClick(`/app/chronos/case-editor/data/facts?caseId=${caseId}`),
      },
      {
        label: 'Kim',
        id: 'assistant',
        onClick: handleTabClick(`/app/chronos/case-editor/assistant?caseId=${caseId}`),
      },
    ],
    [caseId, handleTabClick],
  );

  return <Tabs items={tabItems} activeItem={currentView || ''} />;
};

export default NavigationTabs;
