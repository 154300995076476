import { useState } from 'react';

import { useQuery } from 'react-query';

import { APIBaseChronos } from '../hosts';
import { QueryEntity } from '../types';
import useGetFetchConfig from '../useGetFetchConfig';

interface PresignedPostResponse {
  url: string;
  fields: Record<string, string>;
  expiresIn: number;
}

const ONE_MIN_MS = 60 * 1000;

export const usePresignedPost = (caseId: string) => {
  const { fetchConfigGET } = useGetFetchConfig();

  const [cachedPresignedPost, setPresignedPost] = useState<PresignedPostResponse | undefined>(undefined);
  const [cachedPresignedPostExpiry, setPresignedPostExpiry] = useState<number | undefined>(undefined);

  const fetchPresignedPost = async (): Promise<PresignedPostResponse> => {
    // Use cached details if we're >1 minute away from expiry
    if (cachedPresignedPost && cachedPresignedPostExpiry && cachedPresignedPostExpiry - Date.now() > ONE_MIN_MS) {
      return cachedPresignedPost;
    }

    const response = await fetch(`${APIBaseChronos}/api/storage/presigned-post?caseId=${caseId}`, fetchConfigGET);
    if (!response.ok) {
      throw new Error('Fetching presigned post failed');
    }

    const res = await response.json();

    setPresignedPost(res);
    setPresignedPostExpiry(Date.now() + 10000);

    return res;
  };

  return useQuery([QueryEntity.PresignedPost, caseId], fetchPresignedPost, {
    // Refetch the S3 presigned URL every 9 minutes, as they expire after 10 minutes
    // This should ensure we always have a valid presigned URL
    refetchInterval: 9 * ONE_MIN_MS,
    refetchOnWindowFocus: false,
  });
};
