import { APIBaseChronos } from 'api/hosts';
import useUpdateFact from 'api/mutations/useUpdateFact';
import { RelevanceOptions } from 'constants/filterOptions';
import { PlainTextContent, renderHighlightedTextWithLinks } from 'helpers/textRendering';
import { useDocumentNavigation } from 'screens/Chronos/CaseEditor/DataView/FactsEditor/utils/navigationUtils';
import { Fact, Location, Relevance } from 'types';

import TooltipIcon from '../../atoms/TooltipIcon';
import CommentPopup from '../Comments';
import EditableField from '../EditableField';
import DropdownInput from './DropdownInput';

interface FactDetailProps {
  fact: Fact;
  significanceText: string;
  relevanceText: string;
  updateRelevance: (v: Relevance) => void;
  compressedVersion?: boolean;
}

const FactDetail = ({
  fact,
  significanceText,
  relevanceText,
  updateRelevance,
  compressedVersion = false,
}: FactDetailProps) => {
  const uploadDate = new Date(fact.createdDate);
  const { goToDocReference } = useDocumentNavigation();
  const { mutate: updateFact, isLoading } = useUpdateFact({ analyticsLocation: 'Fact' });

  const handleUpdateFact = (newFact: string) => {
    updateFact({ field: 'significance', value: newFact, factId: fact.id, caseId: fact.caseId });
  };

  return (
    <div className={`${compressedVersion ? 'text-xs' : 'text-sm'}`}>
      <div className="mt-2 border border-x-0 border-t-0 border-dashed border-blue-200" />

      <div className="flex gap-4 py-4 pl-2 pr-4">
        <div className="w-4/5">
          <div className="mb-1 flex items-center gap-1 px-2 font-semibold text-gray-800">
            Significance
            <TooltipIcon
              tooltipId={`significance-tooltip-${fact.id}`}
              tooltipContent="A short analysis of the significance of the fact as it pertains to the key case issues and the context of the document it is extracted from."
              className="mr-2 h-4 w-4 cursor-pointer"
            />
          </div>
          <div className="px-2 text-gray-800">
            <EditableField value={significanceText} onSave={handleUpdateFact} isLoading={isLoading} />
          </div>
        </div>

        <div className="border border-y-0 border-r-0 border-dashed border-blue-200 pl-6">
          <div className="w-36">
            <div className="mb-1 flex items-center gap-1 font-semibold text-gray-800">
              Relevant
              <TooltipIcon
                tooltipId={`relevance-tooltip-${fact.id}`}
                tooltipContent="Is this fact relevant to the key case issues."
                className="mr-2 h-4 w-4 cursor-pointer"
                place="left"
              />
            </div>
            <div
              className={`${
                {
                  [Relevance.YES]: 'text-green-700',
                  [Relevance.NO]: 'text-red-500',
                  [Relevance.MAYBE]: 'text-orange-400',
                }[relevanceText] ?? ''
              }`}
            >
              <DropdownInput
                entryFieldKey={'relevance'}
                entryFieldValue={relevanceText}
                endpointToUpdate={`${APIBaseChronos}/api/case/${fact.caseId}/fact/${fact.id}`}
                options={RelevanceOptions}
                updateRelevance={updateRelevance}
              />
            </div>

            <div className="mb-1 mt-2 flex items-center gap-1 font-semibold text-gray-800">
              Classification
              <TooltipIcon
                tooltipId={`classification-tooltip-${fact.id}`}
                tooltipContent="Fact classification. One of Primary, Secondary, Future or Litigation."
                className="mr-2 h-4 w-4 cursor-pointer"
                place="left"
              />
            </div>
            <div>
              {fact.classification ? fact.classification.charAt(0).toUpperCase() + fact.classification.slice(1) : ''}
            </div>
          </div>
        </div>
      </div>

      <div className="border border-x-0 border-t-0 border-dashed border-blue-200"></div>

      <div className="py-4 pl-2 pr-4">
        <div className="mb-1 px-2 font-semibold text-gray-800">Documents</div>
        <div>
          {fact.locations.map((factLocation: Location, index: number) => {
            const pageNumber =
              factLocation?.boundingBoxes?.length > 0 ? factLocation?.boundingBoxes?.[0]?.pageNumber : 1;
            const file = factLocation.doc?.file?.name;
            const documentType = factLocation.doc?.type;
            const documentDateText = factLocation.doc?.dateText;
            return (
              <div
                key={`${factLocation.docId}-${index}`}
                className="flex items-baseline justify-between gap-4 border border-x-0 border-t-0 border-dashed px-2 pb-1"
              >
                <div className="flex items-baseline gap-4">
                  <div
                    key={factLocation.docId}
                    className="cursor-pointer overflow-hidden text-ellipsis text-blue-500 hover:text-blue-600 hover:underline"
                    onClick={() => {
                      if (compressedVersion) {
                        goToDocReference(factLocation.docId, fact.id, pageNumber);
                      } else {
                        goToDocReference(factLocation.docId, fact.id, pageNumber, true);
                      }
                    }}
                  >
                    {file}
                  </div>
                  <span className="rounded-sm bg-brandTertiary bg-opacity-30 px-2 text-gray-600">{documentType}</span>
                  {documentDateText ? (
                    <span className="text-xs text-gray-600">
                      (<span className="text-xs">{documentDateText})</span>
                    </span>
                  ) : null}
                </div>

                <div className="flex items-baseline gap-6">
                  <span className="text-xs italic text-gray-600">
                    (uploaded: {`${uploadDate.getDate()}/${uploadDate.getMonth() + 1}/${uploadDate.getFullYear()}`})
                  </span>
                </div>
              </div>
            );
          })}
        </div>

        <div>
          <div className="mb-1 mt-3 px-2 font-semibold text-gray-800">Source Text</div>
          <div className="px-2 text-gray-500">
            <PlainTextContent html={fact.sourceText} maxLength={600} />
          </div>
        </div>
      </div>

      <div className="border-2 border-x-0 border-t-0 border-blue-200"></div>

      <div className="flex justify-between gap-10 rounded-b-lg bg-slate-100 px-4">
        <div>
          <div className="pb-1 pt-2 font-semibold">Comments</div>
          <div>
            {fact.comments && fact.comments.length > 0 ? (
              renderHighlightedTextWithLinks(fact.comments[0].content)
            ) : (
              <span className="font-light text-gray-500">No comments yet</span>
            )}
          </div>
        </div>
        <div className="flex items-center gap-2 py-3">
          <CommentPopup
            caseId={fact.caseId}
            factId={fact.id}
            commentsCount={fact.comments.length}
            comments={fact.comments}
          />
        </div>
      </div>
    </div>
  );
};

export default FactDetail;
