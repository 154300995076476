import { useState } from 'react';

import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { UseQueryResult } from 'react-query';

interface UseRemoveFile {
  caseId: string;
  refetchFiles: UseQueryResult['refetch'];
}

const useRemoveFile = ({ refetchFiles }: UseRemoveFile) => {
  const [fileRemoving, setFileRemoving] = useState('');
  const { getFetchConfig } = useGetFetchConfig();

  const handleRemoveFile = async (fileId: string) => {
    setFileRemoving(fileId);
    const fetchConfig = getFetchConfig({ method: 'DELETE' });

    await fetch(`${APIBaseChronos}/api/files/${fileId}`, fetchConfig);
    await refetchFiles();
    setFileRemoving('');
  };

  return { handleRemoveFile, fileRemoving };
};

export default useRemoveFile;
