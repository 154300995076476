import React, { useState } from 'react';

import { faPencil, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCreateCase } from 'api/mutations/useCreateCase';
import Button from 'components/atoms/Button';
import formatDate from 'helpers/formatDate';
import { cn } from 'helpers/shadCnUtils';
import { FlaskConical } from 'lucide-react';
import { Matter } from 'types';

export interface MatterItemComponentProps {
  matter: Matter;
  isCreator: boolean;
  isSuperAdmin: boolean;
  onEdit: (id: string) => void;
  onView: (id: string) => void;
  personalMatter?: boolean;
}

const MatterItemComponent = ({
  matter,
  isCreator,
  onEdit,
  onView,
  isSuperAdmin,
  personalMatter = false,
}: MatterItemComponentProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { mutate: createCase } = useCreateCase(matter.type);

  const matterHasCases = matter.cases && matter.cases.length > 0 && matter.cases.some((c) => !c.isRemoved);

  return (
    <div
      className={cn(
        'flex w-full flex-col items-start justify-start rounded-md border px-4 py-2 text-gray-800 shadow-sm transition-all',
        personalMatter
          ? 'border-2 border-purple-300 bg-purple-50/30 hover:border-purple-400 hover:bg-purple-50/50'
          : 'border-gray-200',
      )}
    >
      <div className="flex w-full flex-row items-center justify-between">
        <div className="flex items-center">
          {personalMatter ? (
            <div className="mb-0.5 flex items-center gap-1 rounded-full bg-purple-100 px-2 py-0.5">
              <FlaskConical className="h-3 w-3 text-purple-500" />
              <span className="text-sm font-medium text-purple-700">Personal Matter</span>
            </div>
          ) : (
            <div className="text-sm font-semibold not-italic">{matter.name}</div>
          )}
        </div>
        <div className="right-0 flex items-center justify-between overflow-hidden text-xs font-semibold not-italic leading-5 text-green-700">
          Created: {formatDate(matter.createdDate)}
        </div>
      </div>
      <div className="flex w-full justify-between gap-4">
        {matter.description && (
          <button
            className={`overflow-hidden text-left text-xs font-normal not-italic leading-4 ${
              isExpanded ? '' : 'line-clamp-3'
            }`}
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
          >
            {matter.description}
          </button>
        )}
        {matter.code && <div className="text-xs not-italic text-gray-500">{matter.code}</div>}
      </div>

      <div className="flex w-full items-center justify-between">
        <div className="mt-2 flex flex-row gap-4">
          {personalMatter ? (
            <>
              {matterHasCases && (
                <Button rounded="base" size="xs" text="View" type="primary" onClick={() => onView(matter.id)} />
              )}
              <Button
                rounded="base"
                size="xs"
                text="Upload"
                type={matterHasCases ? 'secondary' : 'primary'}
                onClick={() => createCase(matter.id)}
                icon={
                  <FontAwesomeIcon
                    icon={faPlus}
                    className={cn('mr-2 text-gray-200', matterHasCases && 'text-gray-800')}
                  />
                }
              />
            </>
          ) : (
            <>
              <Button rounded="base" size="xs" text="View" type="primary" onClick={() => onView(matter.id)} />
              {(isCreator || isSuperAdmin) && (
                <Button
                  rounded="base"
                  size="xs"
                  text="Edit"
                  type="secondary"
                  onClick={() => onEdit(matter.id)}
                  icon={<FontAwesomeIcon icon={faPencil} className="mr-2 text-gray-800" />}
                />
              )}
            </>
          )}
        </div>
        {matter.editedDate && (
          <div className="mt-3 flex items-start rounded bg-yellow-200 bg-opacity-20 px-2 py-1 text-xs font-normal italic leading-4">
            {`Last updated ${matter.editedBy?.email ? `by ${matter.editedBy?.email}` : ''} on ${formatDate(
              matter.editedDate,
              true,
            )}`}
          </div>
        )}
      </div>
    </div>
  );
};

export default MatterItemComponent;
