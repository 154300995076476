import { APIBaseChronos } from 'api/hosts';
import { QueryEntity } from 'api/types';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { Classification, Relevance } from 'types';

export const useCreateChronologyFact = (chronologyId: string, docId: string, caseId: string) => {
  const { getFetchConfig } = useGetFetchConfig();
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      description,
      factDateText,
      significance,
      classification,
      relevance,
    }: {
      description: string;
      factDateText: string;
      significance: string;
      classification: Classification;
      relevance: Relevance;
    }) => {
      const fetchConfig = getFetchConfig({
        method: 'POST',
        data: {
          description,
          factDateText,
          significance,
          classification,
          relevance,
        },
      });
      const response = await fetch(
        `${APIBaseChronos}/api/case/${caseId}/chronology/${chronologyId}/doc/${docId}/add-fact`,
        fetchConfig,
      );
      if (!response.ok) {
        toast.error('Failed to create new fact');
        return;
      }
      toast.success('New fact created');
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryEntity.Chronology, { chronologyId }]);
      },
      onError: (error) => {
        console.error('Fetch Error: ', error);
        toast.error('Failed to create new fact');
      },
    },
  );
};
