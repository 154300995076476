import React, { useState } from 'react';

import { faArrowLeft, faArrowRight, faCheck, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { trackCustomEvent } from 'analytics/Mixpanel';
import { useProcessCase } from 'api/mutations/useProcessCase';
import Button from 'components/atoms/Button';
import { Input } from 'components/atoms/input';
import { Label } from 'components/atoms/label';
import { Textarea } from 'components/atoms/textarea';
import { DISPUTE_STATUS_OPTIONS } from 'constants/disputeStatusOptions';
import { reactSelectStylesCaseCreator } from 'constants/styles';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { MyOptionType } from 'types';

interface RequiredInfoProps {
  name: string;
  setName: (value: string) => void;
  legalIssues: string;
  setLegalIssues: (value: string) => void;
  type: string;
  setType: (value: string) => void;
  parties: string;
  setParties: (value: string) => void;
}

const RequiredInfo = ({
  name,
  setName,
  legalIssues,
  setLegalIssues,
  type,
  setType,
  parties,
  setParties,
}: RequiredInfoProps) => {
  return (
    <div className="flex flex-col gap-4">
      <div>
        <Label className="text-sm font-medium text-gray-700">Case Name</Label>
        <Input
          className="max-h-14 rounded-md border-gray-300"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter the name of the case"
        />
      </div>

      <div className="mt-2 border-b border-gray-300"></div>

      <div>
        <Label className="text-sm font-medium text-gray-700">Case Issues</Label>
        <Textarea
          className="max-h-36 min-h-28 rounded-md border-gray-300"
          value={legalIssues}
          onChange={(e) => setLegalIssues(e.target.value)}
          placeholder="Outline the key issues you want to explore, either in list format or prose."
        />
      </div>

      <div>
        <Label className="text-sm font-medium text-gray-700">Case Type</Label>
        <Input
          id="caseTypeInput"
          className="max-h-14 rounded-md border-gray-300"
          value={type}
          onChange={(e) => setType(e.target.value)}
          placeholder="Arbitration, Employment Dispute, Public Inquiry, Commercial Litigation, etc..."
        />
      </div>

      <div>
        <Label className="text-sm font-medium text-gray-700">Case Parties</Label>
        <Input
          id="casePartiesInput"
          className="max-h-12 rounded-md border-gray-300"
          value={parties}
          onChange={(e) => setParties(e.target.value)}
          placeholder="Key actors or entities in the case."
        />
      </div>

      <div className="mt-2 border-b border-gray-300"></div>
    </div>
  );
};

interface OptionalInfoProps {
  keyContext: string;
  setKeyContext: (value: string) => void;
  keyTimePeriod: string;
  setKeyTimePeriod: (value: string) => void;
  disputeStatus: MyOptionType | undefined;
  setDisputeStatus: (value: MyOptionType | undefined) => void;
}

const OptionalInfo = ({
  keyContext,
  setKeyContext,
  keyTimePeriod,
  setKeyTimePeriod,
  disputeStatus,
  setDisputeStatus,
}: OptionalInfoProps) => {
  return (
    <div className="flex flex-col gap-4">
      <div>
        <Label className="text-sm font-medium text-gray-700">Additional Case Context (Optional)</Label>
        <Textarea
          id="caseContextInput"
          className="max-h-40 min-h-36 rounded-md border-gray-300"
          value={keyContext}
          onChange={(e) => setKeyContext(e.target.value)}
          placeholder='Any other contextual information Wexler should be aware of. E.g. "There is a parallel litigation happening
                  in a different country with similar issues" or "The claimant is a public figure".'
        />
      </div>
      <div>
        <Label className="text-sm font-medium text-gray-700">Case Time Period (Optional)</Label>
        <Textarea
          className="max-h-12 rounded-md border-gray-300"
          id="caseTimePeriodInput"
          value={keyTimePeriod}
          onChange={(e) => setKeyTimePeriod(e.target.value)}
          placeholder={
            'The estimated time period of the documents, e.g. "1960 to 1990" or "Early 2000s" (don\'t worry about the formatting of the dates).'
          }
        />
      </div>

      <div>
        <Label className="text-sm font-medium text-gray-700">Dispute Status (Optional)</Label>
        <Select
          options={Object.values(DISPUTE_STATUS_OPTIONS)}
          className={`w-full rounded-md text-sm outline-none`}
          styles={reactSelectStylesCaseCreator}
          onChange={(newValue) => setDisputeStatus(newValue)}
          value={disputeStatus}
          placeholder={'Pre-Action, Claim, Trial, Post-Trial'}
        />
      </div>
    </div>
  );
};

interface FullCaseUploaderProps {
  name: string;
  legalIssues: string;
  type: string;
  parties: string;
  keyContext: string;
  keyTimePeriod: string;
  disputeStatus: MyOptionType | undefined;
  setName: (value: string) => void;
  setLegalIssues: (value: string) => void;
  setType: (value: string) => void;
  setParties: (value: string) => void;
  setKeyContext: (value: string) => void;
  setKeyTimePeriod: (value: string) => void;
  setDisputeStatus: (value: MyOptionType | undefined) => void;
  uploadDisabled: boolean;
}

const FullCaseUploader = ({
  name,
  legalIssues,
  type,
  parties,
  keyContext,
  keyTimePeriod,
  disputeStatus,
  setName,
  setLegalIssues,
  setType,
  setParties,
  setKeyContext,
  setKeyTimePeriod,
  setDisputeStatus,
  uploadDisabled,
}: FullCaseUploaderProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const caseId = searchParams.get('caseId') ?? '';

  const [step, setStep] = useState(0);
  const { mutate: processCase } = useProcessCase();

  const handleUpdateCase = () => {
    trackCustomEvent('Full case uploaded', {
      caseId,
    });
    processCase(
      {
        caseId,
        name,
        type,
        legalIssues,
        parties,
        keyContext,
        keyTimePeriod,
        disputeStatus: disputeStatus?.value,
      },
      {
        onSuccess: () => {
          navigate(`/app/chronos/case-editor/summary?caseId=${caseId}`);
        },
      },
    );
  };

  const StepIndicator = () => (
    <div className="flex items-center justify-center space-x-4 text-sm">
      <div className={`flex items-center ${step === 0 ? 'text-brandSecondary' : 'text-gray-400'}`}>
        <div
          className={`flex h-6 w-6 items-center justify-center rounded-full border-2 border-brandSecondary bg-brandSecondary text-white`}
        >
          {step === 0 ? '1' : <FontAwesomeIcon icon={faCheck} className="h-3 w-3" />}
        </div>
        <span className="ml-2 font-medium">Required Info</span>
      </div>
      <div className="h-0.5 w-16 bg-gray-300" />
      <div className={`flex items-center ${step === 1 ? 'text-brandSecondary' : 'text-gray-400'}`}>
        <div
          className={`h-6 w-6 rounded-full border-2 ${step === 1 ? 'border-brandSecondary bg-brandSecondary text-white' : 'border-gray-300'} flex items-center justify-center`}
        >
          2
        </div>
        <span className="ml-2 font-medium">Optional Info</span>
      </div>
    </div>
  );

  return (
    <>
      <div className="mb-4 flex items-center justify-between border-b border-gray-100 pb-4">
        <div className="flex items-center">
          <FontAwesomeIcon icon={faPenToSquare} className="mr-3 h-4 w-4 text-gray-600" />
          <h2 className="text-lg font-semibold text-gray-900">Details</h2>
        </div>
        <StepIndicator />
      </div>
      <div className="flex flex-col gap-8">
        {step === 0 && (
          <RequiredInfo
            name={name}
            setName={setName}
            legalIssues={legalIssues}
            setLegalIssues={setLegalIssues}
            type={type}
            setType={setType}
            parties={parties}
            setParties={setParties}
          />
        )}
        {step === 1 && (
          <OptionalInfo
            keyContext={keyContext}
            setKeyContext={setKeyContext}
            keyTimePeriod={keyTimePeriod}
            setKeyTimePeriod={setKeyTimePeriod}
            disputeStatus={disputeStatus}
            setDisputeStatus={setDisputeStatus}
          />
        )}
        <div className="flex justify-between">
          {step === 1 && (
            <Button
              type="secondary"
              onClick={() => setStep(0)}
              text="Back"
              rounded="md"
              icon={<FontAwesomeIcon icon={faArrowLeft} className="mr-2 h-4 w-4" />}
            />
          )}
          {step === 0 ? (
            <Button
              onClick={() => setStep(1)}
              text="Next"
              type="secondary"
              rounded="md"
              disabled={!name || !legalIssues || !type || !parties}
              icon={<FontAwesomeIcon icon={faArrowRight} className="ml-2 h-4 w-4" />}
              iconRight
            />
          ) : (
            <Button
              onClick={handleUpdateCase}
              disabled={uploadDisabled || !legalIssues}
              className="flex items-center justify-center rounded-md bg-brandSecondary px-4 py-2 text-white transition-colors hover:bg-brandSecondary-hover disabled:cursor-not-allowed disabled:opacity-50"
              text="Start Processing"
              iconRight
              icon={<FontAwesomeIcon icon={faArrowRight} className="ml-2 h-4 w-4" />}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default FullCaseUploader;
