import { useState } from 'react';

import { trackCustomEvent } from 'analytics/Mixpanel';
import Button from 'components/atoms/Button';
import { DocIds, FilterOption, Filters } from 'types';

import MultiSelectFilter from '../../../../components/Filters/FactTableFilters/MultiselectFilter';
import MultiSelectFilterDocs from '../../../../components/Filters/FactTableFilters/MultiselectFilterDocs';

const FactsFilterPopup = ({
  activeFilters,
  allDocuments,
  setFilters,
  handleClose,
}: {
  activeFilters: Filters;
  allDocuments: DocIds[];
  setFilters: (v: Filters) => void;
  handleClose: () => void;
}) => {
  const [documentFilters, setDocumentFilters] = useState<FilterOption[]>(activeFilters['docIds'] || []);
  const [relevanceFilters, setRelevanceFilters] = useState<FilterOption[]>(activeFilters['relevance'] || []);
  const [classificationFilters, setClassificationFilters] = useState<FilterOption[]>(
    activeFilters['classification'] || [],
  );

  const handleSubmit = () => {
    const newFilters = { ...activeFilters };
    newFilters['docIds'] = documentFilters;
    newFilters['relevance'] = relevanceFilters;
    newFilters['classification'] = classificationFilters;

    trackCustomEvent('Filters applied');
    setFilters(newFilters);
    handleClose();
  };
  const handleClear = () => {
    if (activeFilters['keyFacts']) {
      setFilters({ classification: [], docIds: [], relevance: [], keyFacts: true });
    } else {
      setFilters({ classification: [], docIds: [], relevance: [], keyFacts: false });
    }
    handleClose();
  };

  return (
    <div className="flex flex-col gap-2 px-8 py-8 text-sm">
      <div className="flex items-center justify-center gap-2">
        <p className="mr-2 w-24 border-r">Documents: </p>
        <MultiSelectFilterDocs
          selectedOptions={documentFilters}
          setSelectedOptions={setDocumentFilters}
          allDocuments={allDocuments}
          className="w-64 text-xs"
        />
      </div>
      <div className="flex items-center justify-center gap-2">
        <p className="mr-2 w-24 border-r">Relevance:</p>
        <MultiSelectFilter
          selectedOptions={relevanceFilters}
          setSelectedOptions={setRelevanceFilters}
          filterOption="relevance"
          className="w-64 text-xs"
        />
      </div>
      <div className="flex items-center justify-center gap-2">
        <p className="mr-2 w-24 border-r">Classification:</p>
        <MultiSelectFilter
          selectedOptions={classificationFilters}
          setSelectedOptions={setClassificationFilters}
          filterOption="classification"
          className="w-64 text-xs"
        />
      </div>
      <div className="mb-4 mt-6 flex justify-between px-4">
        <Button type="delete" rounded="md" text="Clear Filters" onClick={handleClear} />
        <Button type="primary" rounded="md" text="Apply Filters" onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default FactsFilterPopup;
