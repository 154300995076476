import { faCircleCheck, faCircleQuestion, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as Calendar } from 'assets/icons/calendar.svg';
import { ReactComponent as ReactInfoYellow } from 'assets/icons/info-yellow.svg';
import { TooltipStyles } from 'constants/styles';
import { Tooltip } from 'react-tooltip';
import { Fact, Relevance } from 'types';

const DocViewerFact = ({ fact }: { fact: Fact }) => {
  const RelevanceIndicator = ({ relevance }: { relevance: Relevance }) => {
    const getRelevanceInfo = () => {
      switch (relevance) {
        case Relevance.YES:
          return { text: 'Relevant', color: 'text-green-800', icon: faCircleCheck };
        case Relevance.MAYBE:
          return { text: 'Maybe Relevant', color: 'text-orange-400', icon: faCircleQuestion };
        case Relevance.NO:
          return { text: 'Not Relevant', color: 'text-red-800', icon: faCircleXmark };
        default:
          return { text: 'Not Relevant', color: 'text-red-800', icon: faCircleXmark };
      }
    };

    const { text, color, icon } = getRelevanceInfo();

    return (
      <p className={`flex w-32 items-center justify-end gap-2 text-xs ${color}`}>
        <FontAwesomeIcon icon={icon} />
        <span>{text}</span>
      </p>
    );
  };
  return (
    <div className="flex w-full items-center justify-between font-normal">
      <div className={`w-full p-2 px-2 pl-2`}>
        <div className="flex w-full justify-between">
          <div className={`flex items-center gap-2 text-xs font-semibold`}>
            <Calendar className="h-3 w-3" />
            {fact.factDateText ? fact.factDateText : '(Date Unknown)'}

            {fact.dateIsAmbiguous && (
              <>
                <ReactInfoYellow
                  data-tooltip-id={`fact-date-text-tooltip-${fact.id}-ambiguous`}
                  data-tooltip-content={fact.dateIsAmbiguousText || 'Date format is ambiguous, please check'}
                  className="cursor-pointer text-sm text-gray-700"
                  style={{ color: '#E3B336' }}
                />
                <Tooltip
                  opacity={1}
                  id={`fact-date-text-tooltip-${fact.id}-ambiguous`}
                  style={TooltipStyles}
                  place={'top-start'}
                />
              </>
            )}
          </div>
          <RelevanceIndicator relevance={fact.relevance} />
        </div>

        <div className="flex justify-between gap-1">
          <div className="w-full pt-1 text-xs text-gray-900">
            {fact.description.length > 70 ? fact.description.slice(0, 70) + '...' : fact.description}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocViewerFact;
