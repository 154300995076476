import { useState } from 'react';

import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Checkbox } from 'components/atoms/checkbox';
import { Input } from 'components/atoms/input';
import { Button } from 'components/atoms/new-button';
import Modal from 'components/molecules/Modals/Settings';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/molecules/table';
import { reactSelectStylesCaseCreator } from 'constants/styles';
import { ArrowUpDown, CircleMinus, Plus } from 'lucide-react';
import Select from 'react-select';
import { MyOptionType, OrgUser, OrgUserOption } from 'types';

interface UserListProps {
  users: OrgUser[];
  selectedUsers: OrgUserOption[];
  userId: string;
  onAdd: (user: MyOptionType) => void;
  onRemove: (userIds: string[]) => void;
}

export const UserList = ({ users, selectedUsers, userId, onAdd, onRemove }: UserListProps) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = useState({});

  const [addUsersModalOpen, setAddUsersModalOpen] = useState(false);

  const columns: ColumnDef<OrgUserOption>[] = [
    {
      id: 'select',
      header: () => null,
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: 'email',
      header: ({ column }) => {
        return (
          <div className="text-left">
            <div
              className="flex items-center gap-1 hover:cursor-pointer"
              onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
            >
              Email
              <ArrowUpDown className="h-4 w-4 text-gray-700" />
            </div>
          </div>
        );
      },
      cell: ({ row }) => <div className="text-left lowercase">{row.getValue('email')}</div>,
    },
    {
      accessorKey: 'actions',
      header: () => (
        <div className="flex justify-end">
          {table.getFilteredSelectedRowModel().rows.length > 0 ? (
            <Button
              variant="destructive"
              size="sm"
              onClick={() => {
                const ids: string[] = [];
                table.getFilteredSelectedRowModel().rows.forEach((row) => {
                  ids.push(row.original.id);
                });
                onRemove(ids);
                setRowSelection({});
              }}
              className="h-8 w-20 border border-red-300 text-xs text-red-500 hover:bg-red-100"
            >
              Remove ({table.getFilteredSelectedRowModel().rows.length})
            </Button>
          ) : (
            <div className="w-20"></div>
          )}
        </div>
      ),
      enableHiding: false,
      cell: ({ row }) => (
        <div className="flex justify-end">
          <Button
            data-testid={`remove-user-${row.original.id}`}
            variant="destructive"
            className="h-8 border border-red-300 px-2 py-1 text-xs text-red-500 hover:bg-red-100"
            size="sm"
            onClick={() => onRemove([row.original.id])}
          >
            <CircleMinus />
          </Button>
        </div>
      ),
    },
  ];

  const table = useReactTable({
    data: selectedUsers,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    initialState: {
      pagination: {
        pageSize: 5,
      },
    },
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  const orgUsersOptions = (users ?? [])
    .filter((u) => !selectedUsers.some((x) => x.id === u.id) && u.id !== userId)
    .map((u) => ({
      value: u.id,
      label: u.email,
    }));

  return (
    <div className="w-full">
      <div className="flex items-center justify-between gap-8 py-4">
        <Input
          placeholder="Search team members already in this matter..."
          value={(table.getColumn('email')?.getFilterValue() as string) ?? ''}
          onChange={(event) => table.getColumn('email')?.setFilterValue(event.target.value)}
          className="max-w-sm"
        />
        <Button
          data-testid="add-users-button"
          onClick={() => setAddUsersModalOpen(true)}
          variant="outline"
          size="default"
          className="bg-buttonPrimary text-xs text-white hover:bg-buttonPrimary-hover xl:text-sm"
        >
          Add Team Members
          <Plus className="h-4 w-4" />
        </Button>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="text-muted-foreground flex-1 text-sm">
          {table.getFilteredSelectedRowModel().rows.length} of {table.getFilteredRowModel().rows.length} row(s)
          selected.
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Button>
          <Button variant="outline" size="sm" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
            Next
          </Button>
        </div>
      </div>

      <Modal
        isOpen={addUsersModalOpen}
        handleClose={() => setAddUsersModalOpen(false)}
        title={
          <div className="flex items-center gap-2">
            <Plus className="h-5 w-5" />
            <p className="text-lg font-medium">Add Users</p>
          </div>
        }
        size="small"
        content={
          <div className="flex flex-col gap-2 p-6">
            <p className="text-sm text-gray-500">Search for users and select them to add them to the matter.</p>
            <Select
              className="mt-2 w-full rounded-lg outline-none"
              styles={reactSelectStylesCaseCreator}
              onChange={onAdd}
              options={orgUsersOptions}
              value={[]}
              placeholder="Select team members"
            />
          </div>
        }
      />
    </div>
  );
};

export default UserList;
