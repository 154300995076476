import { useEffect, useState } from 'react';

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { trackPageView } from 'analytics/Mixpanel';
import { useGetChronologies } from 'api/queries/useGetChronologies';
import SearchBox from 'components/molecules/SearchBox';
import Pagination from 'components/organisms/Pagination';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import DataViewToolbar from 'screens/Chronos/CaseEditor/DataView/components/TableToolbar';
import { useGeneralPagination } from 'screens/Chronos/CaseEditor/hooks/useGeneralPagination';
import { Chronology } from 'types';

import ChronologyListItem from './ListItem';

interface ChronologyListProps {
  caseId: string | null;
}

const ChronologyList = ({ caseId }: ChronologyListProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const initialPageSize = 20;
  const [searchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState('');
  const { pagination, setPagination } = useGeneralPagination({ pageSize: initialPageSize });

  const {
    data: responseChronologies,
    isFetching: isLoadingChronologies,
    refetch: refetchCaseChronologies,
  } = useGetChronologies({
    caseId: caseId ?? '',
    searchQuery,
    page: pagination.pageIndex + 1,
    pageSize: pagination.pageSize,
  });
  const noOfPages = responseChronologies?.pageCount ?? 0;

  const onSearchCall = () => {
    refetchCaseChronologies();
  };

  const goToPage = (val: number) => {
    const newPaginationState = { pageIndex: val, pageSize: initialPageSize };
    setPagination(newPaginationState);
    searchParams.set('page', String(newPaginationState.pageIndex + 1));
    navigate(location.pathname + '?' + searchParams.toString());
  };

  const prevPage = () => goToPage(pagination.pageIndex - 1);
  const nextPage = () => goToPage(pagination.pageIndex + 1);
  const canGetPrevPage = pagination.pageIndex > 0;
  const canGetNextPage = pagination.pageIndex < (responseChronologies?.totalCount ?? 0) / initialPageSize - 1;

  useEffect(() => {
    trackPageView('Chronology List Page');
  }, []);

  return (
    <div className="flex h-full flex-col">
      <DataViewToolbar
        titleSection={
          <>
            <h1 className="text-lg font-semibold">Chronologies</h1>
            <div className="flex w-fit items-center gap-1 rounded border border-brandSecondary border-opacity-60 bg-gray-50 pl-2 pr-1 text-xs font-normal">
              <span>
                <b>{responseChronologies?.totalCount ?? 0}</b> chronologies
              </span>
            </div>
          </>
        }
        searchBar={
          <SearchBox
            value={searchQuery}
            placeholder="Search chronologies"
            onChange={setSearchQuery}
            onSearchCall={onSearchCall}
          />
        }
        pagination={
          <Pagination
            canGetPrevPage={canGetPrevPage}
            canGetNextPage={canGetNextPage}
            prevPage={prevPage}
            nextPage={nextPage}
            currentPage={pagination.pageIndex}
            noOfPages={noOfPages}
            isLoadingFacts={isLoadingChronologies}
            goToPage={goToPage}
          />
        }
      />
      <div className="h-full flex-grow overflow-hidden">
        <div className="h-full overflow-y-auto">
          {isLoadingChronologies ? (
            <div className="flex h-full w-full items-center justify-center">
              <FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />
            </div>
          ) : responseChronologies?.chronologies && responseChronologies?.chronologies.length ? (
            <div className="max-w-full pr-2">
              {responseChronologies.chronologies.map((chronology: Chronology) => {
                return <ChronologyListItem chronology={chronology} key={chronology.id} />;
              })}
            </div>
          ) : (
            <div className="flex w-full flex-col items-center justify-center">
              <div className="mb-5 mt-28 opacity-60">No chronologies found</div>
              <Link
                to={`/app/chronos/case-editor/data/facts?caseId=${caseId}`}
                className="flex h-12 w-36 shrink-0 cursor-pointer flex-row items-center justify-center rounded-md bg-buttonPrimary px-6 py-4 text-center text-base not-italic text-white disabled:cursor-not-allowed disabled:opacity-20"
              >
                Create Now
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChronologyList;
