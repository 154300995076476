import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';
import { UserAction } from 'types';

const useGetUserActions = (userId: string) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<UserAction[]>('fetchUserActions', () => {
    const url = new URL(`${APIBaseChronos}/api/user/${userId}/actions`);

    return fetch(url.toString(), {
      ...fetchConfigGET,
      method: 'GET',
    })
      .then((res) => res.json())
      .catch((err) => {
        console.error(err);
      });
  });
};

export default useGetUserActions;
