import { APIBaseChronos } from 'api/hosts';
import { QueryEntity } from 'api/types';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';
import { OrgUserWithMetadata } from 'types';

const useGetUsersWithAccessToMatter = (matterId: string | undefined) => {
  const { fetchConfigGET } = useGetFetchConfig();

  const getUser = async (): Promise<OrgUserWithMetadata[]> => {
    const response = await fetch(`${APIBaseChronos}/api/matters/${matterId}/users`, fetchConfigGET);
    return response.json();
  };

  return useQuery([QueryEntity.MatterUsers, matterId], getUser, {
    enabled: !!matterId,
    refetchOnWindowFocus: false,
  });
};

export default useGetUsersWithAccessToMatter;
