import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';
import { OrgUserWithMetadata } from 'types';

const useGetUser = () => {
  const { fetchConfigGET } = useGetFetchConfig();

  const getUser = async (): Promise<OrgUserWithMetadata> => {
    const response = await fetch(`${APIBaseChronos}/api/user`, fetchConfigGET);
    return response.json();
  };

  return useQuery(['user'], getUser, { refetchOnWindowFocus: false });
};

export default useGetUser;
