import React from 'react';

import FactDropdown from 'components/molecules/FactDropdown';
import { Fact, Reference } from 'types';

const KeyFactsTimeline = ({
  factProps,
  userId,
  references,
  updateFactData,
}: {
  factProps: Fact[];
  userId: string;
  references: Reference[];
  updateFactData: (eventId: string, data: Partial<Fact>) => void;
}) => {
  // State

  return (
    <>
      {factProps ? (
        <div className="relative flex flex-col gap-4">
          {/* Vertical line */}
          <div className="absolute left-4 top-0 h-full border-l-2 border-gray-300"></div>

          {factProps.map((fact: Fact, index: number) => (
            <div key={index} className="">
              <div className="flex items-center">
                {/* Marker */}
                <div className="absolute left-[7px] h-5 w-5 rounded-full border-2 border-brandSecondary bg-white"></div>
                {/* Fact content */}
                <div key={fact.id} id={fact.id} className="ml-10 w-full rounded border-2 border-opacity-40 bg-gray-50">
                  <FactDropdown fact={fact} updateFactData={updateFactData} />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default KeyFactsTimeline;
