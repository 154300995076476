import { useQuery } from 'react-query';

import { APIBaseChronos } from '../hosts';
import { QueryEntity } from '../types';
import useGetFetchConfig from '../useGetFetchConfig';

export const useGetDocPresignedURL = (caseId: string, docId: string) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery(
    [QueryEntity.PresignedGetDocument, { caseId, docId }],
    async () => {
      try {
        const downloadResult = await fetch(`${APIBaseChronos}/api/case/${caseId}/docs/${docId}`, fetchConfigGET);

        if (!downloadResult.ok) throw new Error('Fetching document failed');

        return await downloadResult.text();
      } catch {
        return null;
      }
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};
