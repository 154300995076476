import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useMutation } from 'react-query';

interface UpdateCaseParameters {
  caseId: string | null;
  updateDetailsActive: boolean;
  caseIssues: string;
  caseParties: string;
  caseContext: string;
  caseTimePeriod: string;
}

const useUpdateCase = () => {
  const { getFetchConfig } = useGetFetchConfig();

  const mutation = useMutation(
    async ({
      caseId,
      updateDetailsActive,
      caseIssues,
      caseParties,
      caseContext,
      caseTimePeriod,
    }: UpdateCaseParameters) => {
      const fetchConfig = getFetchConfig({
        method: 'PATCH',
        data: {
          isUpdateSynopsis: updateDetailsActive,
          legalIssues: updateDetailsActive ? caseIssues : undefined,
          parties: updateDetailsActive ? caseParties : undefined,
          keyContext: updateDetailsActive ? caseContext : undefined,
          keyTimePeriod: updateDetailsActive ? caseTimePeriod : undefined,
        },
      });

      const response = await fetch(`${APIBaseChronos}/api/case/${caseId}/re-submit`, fetchConfig);
      if (!response.ok) {
        throw new Error('Failed to re-submit case');
      }
    },
  );

  return mutation;
};

export default useUpdateCase;
