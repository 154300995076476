import { faComment, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Copy from 'components/atoms/Copy';
import Accordion from 'components/molecules/Accordion';
import { Subquestion } from 'types';

import formatSummary from '../../helpers/formatHTML';

const handleCopyClick = (content: string) => {
  const text = content
    .replace(/\[.*?\]/g, '')
    .replace(/<b>/g, '')
    .replace(/<\/b>/g, '')
    .replace(/<br>/g, '\n');
  navigator.clipboard.writeText(text);
};

const ThemeContent = ({
  content,
  subquestions,
  scrollToRow,
  id,
}: {
  content: string;
  subquestions: Subquestion[];
  scrollToRow: (id: string) => void;
  id?: string;
}) => {
  return (
    <div className="mt-2 text-base leading-relaxed text-gray-800">
      <div className="pr-2">
        <p id={`${id}-text`} className="text-sm leading-relaxed">
          {formatSummary(content, scrollToRow)}
        </p>
        <div className="mb-8 mt-4 flex gap-2 text-gray-800">
          <Copy copyHandler={() => handleCopyClick(content)} />
        </div>
      </div>

      {subquestions && subquestions.length > 0 && (
        <>
          <div className="mb-4 pl-4 text-lg font-semibold">
            <FontAwesomeIcon icon={faComment} className="mr-2 h-5 w-5 cursor-pointer text-gray-800" />
            Related Questions
          </div>

          <div className="mb-8 flex flex-col">
            {subquestions.map((subquestion, index) => (
              <div key={index} id={`${id}-subquestion-${index}`}>
                <Accordion
                  title={<p className="pr-12 text-sm hover:text-blue-500">{subquestion.question}</p>}
                  content={<SubquestionContent content={subquestion.answer} scrollToRow={scrollToRow} />}
                  icon={<FontAwesomeIcon icon={faPlus} className="text-blue-500 transition-transform duration-300" />}
                  className="border-b bg-white p-4"
                />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const SubquestionContent = ({ content, scrollToRow }: { content: string; scrollToRow: (id: string) => void }) => {
  return (
    <div className="mt-2 pr-2 text-base leading-relaxed text-gray-800">
      <p className="text-sm leading-relaxed">{formatSummary(content, scrollToRow)}</p>
      <div className="mb-6 mt-4 flex gap-2">
        <Copy copyHandler={() => handleCopyClick(content)} />
      </div>
    </div>
  );
};

export default ThemeContent;
