import React, { useEffect, useState } from 'react';

import { faArrowLeft, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSaveCase } from 'api/mutations/useSaveCase';
import { useGetCase } from 'api/queries/useGetCase';
import Button from 'components/atoms/Button';
import { Label } from 'components/atoms/label';
import { Switch } from 'components/atoms/switch';
import PermittedFilesChip from 'components/molecules/PermittedFilesChip';
import FileUploaderComponent from 'components/organisms/DocumentAdder';
import { DISPUTE_STATUS_OPTIONS } from 'constants/disputeStatusOptions';
import { cn } from 'helpers/shadCnUtils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MyOptionType } from 'types';

import CaseUploader from './components/FullCaseUploader';
import MiniCaseUploader from './components/LightCaseUploader';

enum UploadMode {
  Full = 'full',
  Light = 'light',
}

const CaseCreator = () => {
  // State
  const [name, setName] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [legalIssues, setLegalIssues] = useState<string>('');
  const [parties, setParties] = useState<string>('');
  const [keyTimePeriod, setKeyTimePeriod] = useState<string>('');
  const [keyContext, setKeyContext] = useState<string>('');
  const [disputeStatus, setDisputeStatus] = useState<MyOptionType | null>();

  const [uploadDisabled, setUploadDisabled] = useState(true);
  const [totalUnprocessedDocs, setTotalUnprocessedDocs] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const caseId = searchParams.get('caseId') ?? '';
  const mode = searchParams.get('mode') ?? UploadMode.Full;

  const navigate = useNavigate();

  // Queries
  const { data: responseCase, isLoading: isLoadingCase } = useGetCase(caseId);
  const { mutate: saveCase } = useSaveCase(caseId || '');

  // Load in any existing case metadata
  useEffect(() => {
    if (responseCase) {
      setName(responseCase?.name ?? '');
      setLegalIssues(responseCase?.legalIssues ?? '');
      setParties(responseCase?.parties ?? '');
      setKeyTimePeriod(responseCase?.keyTimePeriod ?? '');
      setKeyContext(responseCase?.keyContext ?? '');
      setDisputeStatus(DISPUTE_STATUS_OPTIONS[responseCase?.disputeStatus as keyof typeof DISPUTE_STATUS_OPTIONS]);
      setType(responseCase?.type ?? '');
    }
  }, [responseCase]);

  const handleClickBack = () => {
    const toastId = toast.loading('Saving case...');
    saveCase(
      {
        name,
        type,
        legalIssues,
        parties,
        keyContext,
        keyTimePeriod,
        disputeStatus: disputeStatus?.value ?? '',
      },
      {
        onSuccess: () => {
          toast.update(toastId, {
            render: 'Case saved successfully',
            type: 'success',
            isLoading: false,
            autoClose: 1500,
          });
          navigate(`/app/chronos/explore?matterId=${responseCase?.matterId}`);
        },
        onError: () => {
          toast.update(toastId, {
            render: 'Failed to save case',
            type: 'error',
            isLoading: false,
            autoClose: 1500,
          });
          navigate(`/app/chronos/explore?matterId=${responseCase?.matterId}`);
        },
      },
    );
  };

  return (
    <div className="relative flex min-h-screen w-full flex-row gap-6 overflow-auto bg-gray-50">
      <div className="flex w-full flex-col items-start justify-start px-8 py-6">
        {!isLoadingCase && (
          <>
            <div className="mb-4 flex w-full items-center justify-between">
              <div className="flex items-center">
                <Button
                  className="rounded-full px-2 py-1 hover:text-gray-600"
                  onClick={handleClickBack}
                  icon={<FontAwesomeIcon icon={faArrowLeft} className="h-4 w-4" />}
                  text=""
                />
                <h1 className="ml-2 text-xl font-bold text-gray-900">Create New Case</h1>
              </div>

              <div className="flex items-center space-x-2 rounded-md border bg-white px-2 py-1">
                <div className="flex items-center space-x-2">
                  <Label
                    className={cn(
                      'text-sm text-gray-700 transition-colors duration-150',
                      mode === UploadMode.Light && 'text-brandSecondary',
                    )}
                  >
                    Light Upload
                  </Label>
                  <Switch
                    className="bg-gray-100"
                    checked={mode === UploadMode.Full}
                    onCheckedChange={() => {
                      setSearchParams((params) => ({
                        ...Object.fromEntries(params),
                        mode: mode === UploadMode.Full ? UploadMode.Light : UploadMode.Full,
                      }));
                    }}
                  />
                  <Label
                    className={cn(
                      'text-sm text-gray-700 transition-colors duration-150',
                      mode === UploadMode.Full && 'text-brandSecondary',
                    )}
                  >
                    Full Upload
                  </Label>
                </div>
              </div>
            </div>

            <div className="flex h-full w-full flex-row gap-8">
              <div className="flex w-full flex-row gap-8">
                <div className="flex w-1/2 flex-col justify-between rounded-xl border border-gray-200 bg-white p-6 shadow-sm">
                  <div>
                    {mode === UploadMode.Light ? (
                      <MiniCaseUploader
                        name={name}
                        setName={setName}
                        legalIssues={legalIssues}
                        setLegalIssues={setLegalIssues}
                        uploadDisabled={uploadDisabled}
                      />
                    ) : (
                      <CaseUploader
                        name={name}
                        legalIssues={legalIssues}
                        type={type}
                        parties={parties}
                        keyContext={keyContext}
                        keyTimePeriod={keyTimePeriod}
                        disputeStatus={disputeStatus}
                        setName={setName}
                        setLegalIssues={setLegalIssues}
                        setType={setType}
                        setParties={setParties}
                        setKeyContext={setKeyContext}
                        setKeyTimePeriod={setKeyTimePeriod}
                        setDisputeStatus={setDisputeStatus}
                        uploadDisabled={uploadDisabled}
                      />
                    )}
                  </div>
                </div>

                <div className="w-1/2 rounded-xl border border-gray-200 bg-white p-6 shadow-sm">
                  <div className="mb-6 flex items-center justify-between border-b border-gray-100 pb-4">
                    <div className="flex items-center">
                      <FontAwesomeIcon icon={faFile} className="mr-3 h-4 w-4 text-gray-600" />
                      <h2 className="text-lg font-semibold text-gray-900">
                        Files{' '}
                        {totalUnprocessedDocs ? (
                          <span className="ml-1 text-sm">{`(${totalUnprocessedDocs})`}</span>
                        ) : (
                          ''
                        )}
                      </h2>
                    </div>
                    <PermittedFilesChip />
                  </div>

                  <FileUploaderComponent
                    setUploadDisabled={setUploadDisabled}
                    setTotalUnprocessedDocs={setTotalUnprocessedDocs}
                    caseId={caseId || ''}
                    isCaseCreator={true}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CaseCreator;
