import { faCircleCheck, faCircleQuestion, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIBaseChronos } from 'api/hosts';
import useUpdateFact from 'api/mutations/useUpdateFact';
import { ReactComponent as ReactInfoYellow } from 'assets/icons/info-yellow.svg';
import { TooltipStyles } from 'constants/styles';
import { Tooltip } from 'react-tooltip';
import { useDocumentNavigation } from 'screens/Chronos/CaseEditor/DataView/FactsEditor/utils/navigationUtils';
import { Fact, Relevance } from 'types';

import CalendarEditPopup from '../CalendarPopup';
import EditableField from '../EditableField';

interface FactTitleProps {
  open: boolean;
  fact: Fact;
  factText: string;
  dateText: string;
  updateDate: (v: string) => void;
  compressedVersion?: boolean;
  reference?: number;
  hideRelevanceIndicator?: boolean;
  setIsDateDirty?: (v: boolean) => void;
}

const FactTitle = ({
  open,
  fact,
  factText,
  dateText,
  updateDate,
  reference,
  hideRelevanceIndicator,
  setIsDateDirty,
  compressedVersion = false,
}: FactTitleProps) => {
  const { goToDocReference } = useDocumentNavigation();
  const { mutate: updateFact, isLoading } = useUpdateFact({ analyticsLocation: 'Fact' });

  const handleUpdateFact = (newFact: string) => {
    updateFact({ field: 'description', value: newFact, factId: fact.id, caseId: fact.caseId });
  };

  const referenceDigits = reference ? Math.abs(reference).toString().length : 2;
  const referencePosition = referenceDigits === 1 ? 'right-5' : referenceDigits === 2 ? 'right-4' : 'right-3';

  const RelevanceIndicator = ({ relevance }: { relevance: Relevance }) => {
    const getRelevanceInfo = () => {
      switch (relevance) {
        case Relevance.YES:
          return { text: 'Relevant', color: 'text-green-800', icon: faCircleCheck };
        case Relevance.MAYBE:
          return { text: 'Maybe Relevant', color: 'text-orange-400', icon: faCircleQuestion };
        case Relevance.NO:
          return { text: 'Not Relevant', color: 'text-red-800', icon: faCircleXmark };
        default:
          return { text: 'Not Relevant', color: 'text-red-800', icon: faCircleXmark };
      }
    };

    const { text, color, icon } = getRelevanceInfo();

    return (
      <p className={`flex w-32 items-center justify-end gap-2 text-xs ${color}`}>
        <FontAwesomeIcon icon={icon} />
        <span>{text}</span>
      </p>
    );
  };

  return (
    <div className="flex w-full items-center justify-between font-normal">
      <div className={`w-full px-2 pl-2 ${compressedVersion ? 'p-[6px]' : 'p-2'}`}>
        <div className="flex w-full justify-between">
          <div className={`flex items-center gap-2 font-semibold ${compressedVersion ? 'text-xs' : 'text-sm'}`}>
            <CalendarEditPopup
              dateText={dateText}
              entryFieldKey="factDateText"
              updateDate={updateDate}
              compressedVersion={compressedVersion}
              endpointToUpdate={`${APIBaseChronos}/api/case/${fact.caseId}/fact/${fact.id}`}
              setIsDateDirty={setIsDateDirty}
              location="Fact"
            />

            {dateText ? dateText : '(Date Unknown)'}

            {fact.dateIsAmbiguous && (
              <>
                <ReactInfoYellow
                  data-tooltip-id={`fact-date-text-tooltip-${fact.id}-ambiguous`}
                  data-tooltip-content={fact.dateIsAmbiguousText || 'Date format is ambiguous, please check'}
                  className="cursor-pointer text-sm text-gray-700"
                  style={{ color: '#E3B336' }}
                />
                <Tooltip
                  opacity={1}
                  id={`fact-date-text-tooltip-${fact.id}-ambiguous`}
                  style={TooltipStyles}
                  place={'top-start'}
                />
              </>
            )}
          </div>

          <div className="flex gap-0.5 text-xs text-blue-600">
            {fact.locations.map((location, index) => {
              if (location.doc === undefined || location.doc === null) return <></>;
              if (compressedVersion && index === 2) return <div key={`${index}-ellipsis`}>...</div>;
              if (compressedVersion && index > 2) return <div key={`${index}-ellipsis`}></div>;

              const doc = location.doc;
              return (
                <span key={`${doc.id}-${index}`} className="flex items-start justify-between">
                  <span
                    data-tooltip-id={`fact-document-tooltip-${fact.id}-${location.docId}`}
                    data-tooltip-content={doc.file.name}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (compressedVersion) {
                        goToDocReference(location.docId, fact.id, location.boundingBoxes?.[0]?.pageNumber ?? 0);
                      } else {
                        goToDocReference(location.docId, fact.id, location.boundingBoxes?.[0]?.pageNumber ?? 0, true);
                      }
                    }}
                  >
                    {doc.file.name ? doc.file.name.slice(0, 20).trim() + '...' : ''}
                  </span>
                  {doc.type && (
                    <span className="mx-1 rounded-sm bg-brandTertiary bg-opacity-20 px-1 text-gray-600">
                      {doc.type}
                    </span>
                  )}
                  {fact.locations.length > 1 && index < fact.locations.length - 1 ? <>, </> : <></>}
                  <Tooltip
                    opacity={1}
                    id={`fact-document-tooltip-${fact.id}-${location.docId}`}
                    style={TooltipStyles}
                  />
                </span>
              );
            })}
          </div>
        </div>

        <div className="flex justify-between gap-1">
          <div className={`w-5/6 pl-2 pt-1 text-gray-900 ${compressedVersion ? 'text-xs' : 'text-sm'}`}>
            {open ? (
              <EditableField value={factText} onSave={handleUpdateFact} isLoading={isLoading} />
            ) : factText && factText.length > 160 ? (
              factText.slice(0, 160) + '...'
            ) : (
              factText
            )}
          </div>
          {compressedVersion && !hideRelevanceIndicator ? <RelevanceIndicator relevance={fact.relevance} /> : <></>}
          <p
            className={`absolute top-2 text-blue-600 ${
              compressedVersion ? 'text-xs font-normal' : 'text-sm font-bold'
            } ${referencePosition}`}
          >
            {reference}
          </p>
        </div>
      </div>
    </div>
  );
};

export default FactTitle;
