import { useSearchParams, Route, Routes } from 'react-router-dom';

import ChronologyEditor from './ChronologyEditor';
import ChronologyList from './ChronologyList';

const ChronologyViewer = () => {
  // State
  const [searchParams] = useSearchParams();

  const caseId = searchParams.get('caseId');
  return (
    <Routes>
      <Route path="/" element={<ChronologyList caseId={caseId} />} />
      <Route path="view" element={<ChronologyEditor />} />
    </Routes>
  );
};

export default ChronologyViewer;
