import { FilterOptions } from 'constants/filterOptions';
import Select from 'react-select';
import { FilterOption } from 'types';

interface FilterPopupProps {
  selectedOptions: FilterOption[];
  setSelectedOptions: (v: FilterOption[]) => void;
  filterOption: 'relevance' | 'classification';
  className?: string;
}

const FilterPopup: React.FC<FilterPopupProps> = ({
  selectedOptions,
  setSelectedOptions,
  filterOption,
  className = '',
}) => {
  const options = FilterOptions[filterOption] || [];

  const onChange = (selected: any) => {
    setSelectedOptions(selected);
  };

  return (
    <Select
      isMulti
      value={selectedOptions}
      onChange={onChange}
      options={options}
      placeholder="Select..."
      className={className}
    />
  );
};

export default FilterPopup;
