import { useEffect, useState } from 'react';

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PaginationState } from '@tanstack/react-table';
import { trackPageView, trackCustomEvent } from 'analytics/Mixpanel';
import useToggleAll from 'api/mutations/useToggleAllIncluded';
import { useGetDocIds } from 'api/queries/useGetDocIds';
import useFactsData from 'api/queries/useGetFacts';
import { useFetchIncludedFactsCount } from 'api/queries/useGetIncludedFactsCount';
import { useFetchKeyFacts } from 'api/queries/useGetKeyFacts';
import { CHRONOLOGY_STEPS } from 'constants/tours';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Tour from 'reactour';
import { Filters } from 'types';

import FactsEditorTable from './FactsEditorTable';
import FactsEditorToolbar from './FactsEditorToolbar';
import DocViewer from '../../components/DocViewer/FactDocViewer';

const FactsEditor = () => {
  const [searchQueryInput, setSearchQueryInput] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  // Query params
  const [searchParams, setSearchParams] = useSearchParams();
  const caseId = searchParams.get('caseId');
  const pageNumber = searchParams.get('page') || '0';
  const docId = searchParams.get('docId');
  const docPageNumber = parseInt(searchParams.get('docPageNumber') || '0', 10);

  const eventId = searchParams.get('eventId');
  const [isDocViewerOpen, setIsDocViewerOpen] = useState(false);
  useEffect(() => {
    if (docId) {
      setIsDocViewerOpen(true);
    } else {
      setIsDocViewerOpen(false);
    }
  }, [docId]);

  const [keyFactFilterActive, setKeyFactFilterActive] = useState(false);
  const [filters, setFilters] = useState<Filters>({
    docIds: [],
    relevance: [],
    classification: [],
    keyFacts: false,
  });
  const [allFactIds, setAllFactIds] = useState<string[]>([]);
  const [isDateDirty, setIsDateDirty] = useState(false);
  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: parseInt(pageNumber), pageSize: 50 });
  const [includedFactsCount, setIncludedFactsCount] = useState(0);
  const [createChronologyTourOpen, setCreateChronologyTourOpen] = useState(false);
  const [keyFactsSet, setKeyFactsSet] = useState(new Set<string>());
  const [totalFactsCount, setTotalFactsCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  // Custom hooks
  const { mutateAsync: toggleAllIncluded } = useToggleAll(caseId ?? '');
  const navigate = useNavigate();
  const location = useLocation();

  // Data - facts, key facts, counts, docs
  const {
    data: responseFacts,
    isLoading,
    isFetching: isFetchingFacts,
    refetch: refetchCaseFacts,
  } = useFactsData({
    docId: null,
    caseId,
    searchQuery,
    page: pagination.pageIndex + 1,
    pageSize: pagination.pageSize,
    filters,
  });
  const { data: includedFactsCountResponse } = useFetchIncludedFactsCount(caseId);
  const { data: keyFactsData, isLoading: isLoadingKeyFacts } = useFetchKeyFacts(caseId);
  const { data: responseDocs } = useGetDocIds(caseId);
  // ---------------------------------------------------------------------------

  // Handlers
  const handleToggleAll = async (toggleValue: boolean): Promise<void> => {
    if (!caseId) {
      throw new Error('No caseId provided');
    }

    try {
      if (toggleValue) {
        setIncludedFactsCount(totalFactsCount);
      } else {
        setIncludedFactsCount(0);
      }
      await toggleAllIncluded({ caseId, toggleValue, filters, factIds: allFactIds });
    } catch (err) {
      console.error('Error toggling all facts:', err);
      toast.error('There was an error updating the fields. Please try again later.');
      throw err;
    }
  };

  const onSearchCall = async () => {
    trackCustomEvent('Search in Facts Editor');
    if (!docId) {
      // Reset page to 0
      const newPaginationState = { pageIndex: 0, pageSize: pagination.pageSize };
      setPagination(newPaginationState);
      setSearchQuery(searchQueryInput); // Set the actual filter used for fetching
    }
  };

  const clearSearch = async () => {
    setSearchQueryInput('');
    setSearchQuery('');
    await new Promise((resolve) => setTimeout(resolve, 0));
  };

  const handleKeyFactFilterToggle = async () => {
    // If keyFactFilterActive is currently true, then remove the event_id filter from the filters
    goToPage(0);
    if (keyFactFilterActive) {
      searchParams.delete('viewKeyFacts');
      navigate(`${location.pathname}?${searchParams.toString()}`);

      const newFilters = { ...filters, keyFacts: false };
      setFilters(newFilters);
      // Remove
    } else {
      trackCustomEvent('Key Facts Filter Toggled');
      // If keyFactFilterActive is currently false, then fetch key fact event_ids and add to filters
      searchParams.set('viewKeyFacts', 'true');
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }
  };

  // Pagination
  const goToPage = (val: number) => {
    const newPaginationState = { pageIndex: val, pageSize: pagination.pageSize };
    setPagination(newPaginationState);
  };
  const prevPage = () => goToPage(pagination.pageIndex - 1);
  const nextPage = () => goToPage(pagination.pageIndex + 1);

  // useEffects
  useEffect(() => {
    trackPageView('Facts Page');
  }, []);

  // Handle key facts filter
  useEffect(() => {
    if (!keyFactsData) return;
    setKeyFactsSet(new Set(keyFactsData.map((fact) => fact.id)));
    // Check the search params for the key fact true option and if so, then set key fact filters
    if (searchParams.get('viewKeyFacts') === 'true') {
      setKeyFactFilterActive(true);
      setFilters((prevFilters) => ({ ...prevFilters, keyFacts: true }));
    } else {
      setKeyFactFilterActive(false);
    }
  }, [keyFactsData, searchParams, setFilters]);

  // Handle clear search
  useEffect(() => {
    if (searchQuery === '') {
      setAllFactIds([]);
    }
  }, [searchQuery, setAllFactIds]);

  // Handle if we have factIds in the response
  useEffect(() => {
    if (responseFacts) {
      //If result has come from search then we have the factIds in the response
      if (responseFacts.factIds) {
        setAllFactIds(responseFacts.factIds);
      }
      setTotalFactsCount(responseFacts.totalCount);
      setTotalPages(responseFacts.pageCount);
    }
    setIsDateDirty(false);
  }, [responseFacts, setAllFactIds]);

  useEffect(() => {
    if (includedFactsCountResponse !== undefined && includedFactsCountResponse !== null) {
      setIncludedFactsCount(includedFactsCountResponse);
    }
  }, [includedFactsCountResponse]);

  // Start chronology tour from summary page
  useEffect(() => {
    // Check if explore = true is in search params
    if (isLoading || isFetchingFacts) return;
    if (searchParams.get('chronologyTour') === 'true') {
      setCreateChronologyTourOpen(true);

      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete('chronologyTour');
      // Update the URL without the 'chronologyTour' parameter
      setSearchParams(newSearchParams, { replace: true });
    }
  }, [searchParams, setSearchParams, isLoading, isFetchingFacts]);

  return (
    <div className="flex h-full min-w-full flex-col">
      <FactsEditorToolbar
        caseId={caseId}
        // Search
        globalFilter={searchQueryInput}
        clearSearch={clearSearch}
        setGlobalFilter={setSearchQueryInput}
        onSearchCall={onSearchCall}
        // Results
        refetchCaseFacts={refetchCaseFacts}
        resultsLength={totalFactsCount}
        resultsIncludedLength={includedFactsCount ?? 0}
        // Pagination
        canGetPrevPage={pagination.pageIndex > 0}
        canGetNextPage={pagination.pageIndex < totalPages - 1}
        prevPage={prevPage}
        nextPage={nextPage}
        goToPage={goToPage}
        currentPage={pagination.pageIndex}
        noOfPages={totalPages}
        isDateDirty={isDateDirty}
        // Key facts
        loadingKeyFacts={isLoadingKeyFacts}
        hasKeyFacts={Boolean(keyFactsData && keyFactsData.length > 0)}
        keyFactFilterActive={keyFactFilterActive}
        handleKeyFactFilterToggle={handleKeyFactFilterToggle}
        // -----------------
        handleToggleAll={handleToggleAll}
        allDocuments={responseDocs ?? []}
        activeFilters={filters}
        setFilters={setFilters}
      />

      <div className="flex h-full overflow-hidden pb-2">
        {isLoading || isFetchingFacts ? (
          <div className="flex h-full w-full items-center justify-center">
            <FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />
          </div>
        ) : (
          <div
            className={`h-full overflow-y-auto transition-all duration-300 ${isDocViewerOpen ? 'w-7/12' : 'w-full'}`}
          >
            <FactsEditorTable
              facts={responseFacts?.facts || []}
              keyFactIdSet={keyFactsSet}
              caseId={caseId || ''}
              setIsDateDirty={setIsDateDirty}
              setTotalNumberOfIncludedFacts={setIncludedFactsCount}
            />
          </div>
        )}
        <div
          className={`transition-all duration-300 ease-in-out ${
            isDocViewerOpen ? 'ml-2 w-5/12 opacity-100' : 'w-0 opacity-0'
          } overflow-hidden rounded border-2 bg-white`}
        >
          <div
            className={`h-full w-full transition-transform duration-300 ease-in-out ${
              isDocViewerOpen ? 'translate-x-0' : 'translate-x-full'
            }`}
          >
            {docId && (
              <DocViewer docId={docId} caseId={caseId || ''} eventId={eventId || ''} pageNumber={docPageNumber} />
            )}
          </div>
        </div>
      </div>
      <Tour
        startAt={0}
        steps={CHRONOLOGY_STEPS}
        isOpen={createChronologyTourOpen}
        onRequestClose={() => setCreateChronologyTourOpen(false)}
      />
    </div>
  );
};

export default FactsEditor;
