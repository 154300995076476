import React, { useEffect, useMemo, useState } from 'react';

import { faCog, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetCase } from 'api/queries/useGetCase';
import { BreadcrumbHeading } from 'components/molecules/Breadcumb';
import Modal from 'components/molecules/Modals/Settings';
import PageMissing from 'components/molecules/PageMissing';
import { PIPELINE_FREEZE_LOAD_STATUS, PIPELINE_PROCESSING_STATUS, PIPELINE_STATUS } from 'constants/pipelineStatus';
import 'moment-timezone';
import { Navigate, Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import { toast } from 'react-toastify';
import { Case, MatterType } from 'types';

import CaseAssistant from './CaseAssistant';
import CaseSummary from './CaseSummary';
import StatusDisplay from './components/CaseProcessing';
import SettingsModalContent from './components/Modals/SettingsModal';
import NavigationTabs from './components/NavigationTabs';
import RunsOverview from './components/RunsOverview';
import Share from './components/Share';
import DataView from './DataView';
import computeCaseMessage from './helpers/computeCaseMessage';

const CaseEditor = () => {
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const caseId = searchParams.get('caseId');

  const [caseState, setCaseState] = useState<Case | null>();

  const navigate = useNavigate();

  const { isLoading: isLoadingCase, data: caseData, refetch: refetchCase } = useGetCase(caseId || '');

  useEffect(() => {
    if (caseData) {
      if (caseData.type === 'unauthorized') {
        toast.warning('You are not authorised to view this case...');
        navigate(`/app/chronos/matters`);
      } else if (caseData?.id) {
        setCaseState(caseData);
      }
    }
  }, [caseData, navigate]);

  // Use effect which polls the case object for updates
  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (!PIPELINE_FREEZE_LOAD_STATUS.includes(caseState?.runs?.[0]?.status || '')) {
      intervalId = setInterval(() => {
        refetchCase();
      }, 3000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [caseState, refetchCase]);

  const message = useMemo(() => {
    if (!caseState || !caseState.runs || caseState.runs.length === 0) return '';

    const run = caseState.runs[0];
    return computeCaseMessage(run.inQueue, run.queuePosition, run.completedAtEstimate, run.queueEstimateDatetime);
  }, [caseState]);

  const renderContent = () => {
    if (isLoadingCase) {
      return (
        <div className="absolute bottom-0 left-0 right-0 top-0 flex h-full w-full flex-col items-center justify-center">
          <StageSpinner className="m-auto" size={40} color={'#081D57'} />
        </div>
      );
    }

    const successfulRuns = caseState?.runs?.filter((run) => run.status === PIPELINE_STATUS.complete.value);
    if (successfulRuns && successfulRuns.length > 0) {
      return (
        <>
          {caseId && <NavigationTabs caseId={caseId} />}
          <Routes>
            <Route path="summary" element={<CaseSummary />} />
            <Route path="data/*" element={<DataView openSettingsModal={() => setSettingsModalOpen(true)} />} />
            <Route path="assistant" element={<CaseAssistant />} />
            <Route path="*" element={<Navigate to={`/app/chronos/case-editor/summary`} />} />
          </Routes>
        </>
      );
    }
    const mostRecentRunStatus = caseState?.runs?.[0]?.status || '';

    if (
      PIPELINE_PROCESSING_STATUS.includes(mostRecentRunStatus) ||
      mostRecentRunStatus === PIPELINE_STATUS.failed.value
    ) {
      return <StatusDisplay casePipelineStatus={mostRecentRunStatus} message={message} />;
    }

    return (
      <PageMissing
        title={'Case Loading Failed'}
        description={
          <div className="mt-4 text-sm">
            Sorry! We encountered a problem loading this case. Please{' '}
            <a className="text-blue-500 hover:cursor-pointer" href="mailto:support@wexler.ai">
              contact
            </a>{' '}
            Wexler for support.
          </div>
        }
      />
    );
  };

  return (
    <div className="flex h-full w-full flex-col items-start justify-start overflow-y-scroll text-black">
      <div className="h-full w-full bg-center px-6 pb-2 pt-4">
        {!isLoadingCase && caseState && (
          <div className="flex justify-between">
            <div className="flex items-center">
              <BreadcrumbHeading
                pages={[
                  {
                    label: <FontAwesomeIcon icon={faHome} className="h-4 w-4 text-gray-700" />,
                    href: '/app/chronos/matters',
                  },
                  {
                    label: (
                      <div>
                        {caseState?.matter?.name?.length > 30
                          ? `${caseState?.matter?.name?.substring(0, 30)}...`
                          : caseState?.matter?.name}
                      </div>
                    ),
                    href: `/app/chronos/explore?matterId=${caseState?.matterId}`,
                  },
                  {
                    label: (
                      <div className="flex items-center">
                        {caseState.name.length > 40 ? `${caseState.name.substring(0, 40)}...` : caseState.name}

                        {!PIPELINE_PROCESSING_STATUS.includes(caseState?.runs?.[0]?.status) && (
                          <FontAwesomeIcon
                            icon={faCog}
                            className="h-4 w-4 rounded-full p-2 text-brandPrimary hover:cursor-pointer hover:text-brandPrimary-hover"
                            onClick={() => setSettingsModalOpen(true)}
                          />
                        )}
                      </div>
                    ),
                  },
                ]}
              />
            </div>
            <div className="flex items-center gap-2">
              {caseId && caseState && caseState.matter.type === MatterType.Personal && <Share caseId={caseId} />}
              {caseState && <RunsOverview caseState={caseState} />}
            </div>
          </div>
        )}
        {renderContent()}
      </div>
      <Modal
        title={
          <div className="flex items-center gap-4">
            <FontAwesomeIcon icon={faCog} className="text-gray-700" />
            Case Settings
          </div>
        }
        isOpen={settingsModalOpen}
        handleClose={() => setSettingsModalOpen(false)}
        content={<SettingsModalContent caseId={caseId || ''} handleClose={() => setSettingsModalOpen(false)} />}
      />
    </div>
  );
};

export default CaseEditor;
