import { KimMode } from 'types';

interface ModeCopy {
  prompts: string[];
  label: string;
}

const ExampleQuestion = ({
  prompt,
  setQuestionValue,
}: {
  prompt: string;
  setQuestionValue: (value: string) => void;
}) => {
  const onQuestionClick = () => setQuestionValue(prompt);

  return (
    <button
      className="rounded-full border border-gray-300 bg-gray-50 px-4 py-2 text-left text-xs transition-colors hover:border-gray-400 hover:bg-gray-100"
      onClick={onQuestionClick}
    >
      <p className="italic text-gray-600">{prompt}</p>
    </button>
  );
};

const modeCopy: Record<KimMode, ModeCopy> = {
  question_flow: {
    prompts: [
      'What is the relevance of John Smith and their witness statement to the overall case?',
      'Summarise the arguments about data privacy breaches mentioned in the documents',
      'Why were Organisation 123 unable to meet their emissions standards in 2020?',
    ],
    label: 'questions',
  },
  extraction_flow: {
    prompts: [
      'Extract criticisms of Company ABC and label with date of criticism and who criticised them',
      'For each document extract the specific decisions made, the fine amount and regulations broken',
      'For every jurisdiction, extract the name of the relevant employment law and its enforcement start date.',
    ],
    label: 'extractions',
  },
  draft_flow: {
    prompts: [],
    label: 'drafts',
  },
};

const ExampleQuestions = ({ mode, setQuestionValue }: { mode: KimMode; setQuestionValue: (value: string) => void }) => {
  const copy = modeCopy[mode];
  return (
    <div className="mt-8">
      <div className="mt-2 flex w-full flex-1 justify-between gap-4 bg-white text-sm">
        {copy.prompts.map((question) => (
          <ExampleQuestion prompt={question} setQuestionValue={setQuestionValue} />
        ))}
      </div>
    </div>
  );
};

export default ExampleQuestions;
