import React, { createContext, useEffect, useState } from 'react';

import jwtDecode from 'jwt-decode';

import { Connection, File } from '../types';
import { ModalContent } from './ModalContent';

export interface Props {
  token: string;
  onSelect?: (file: File, selectedSource: string) => any;
  onConnectionSelect?: (connection: Connection) => any;
  fileToSave?: File | any;
  selectedObject: Record<string, any>;
  resetSelection: () => void;
  initialConnection?: string;
}

export const EventsContext = createContext({ onSelect: undefined });

export const FilePicker = ({
  token,
  onSelect,
  onConnectionSelect,
  fileToSave,
  selectedObject,
  resetSelection,
  initialConnection,
}: Props) => {
  const [decodedToken, setDecodedToken] = useState<any>(null);

  const handleFileSelect = (file: File, selectedSource: string) => {
    let fileToReturn = file;
    if (file.connection) {
      const { connection, ...rest } = file;
      fileToReturn = rest;
    }
    if (onSelect) onSelect(fileToReturn, selectedSource);
  };

  const handleConnectionSelect = (connection: Connection) => {
    if (onConnectionSelect) onConnectionSelect(connection);
  };

  useEffect(() => {
    if (token?.length) {
      let decoded;
      try {
        decoded = jwtDecode<{ application_id: string; consumer_id: string }>(token);
        setDecodedToken(decoded);
      } catch (e) {
        console.error('Failed to authenticate.');
        setDecodedToken(null);
      }
    }
  }, [token]);

  return (
    <div>
      {decodedToken?.application_id && decodedToken?.consumer_id && (
        <ModalContent
          appId={decodedToken?.application_id}
          consumerId={decodedToken?.consumer_id}
          jwt={token}
          onSelect={handleFileSelect}
          onConnectionSelect={handleConnectionSelect}
          title={'Wexler Secure File Picker'}
          subTitle={
            Object.keys(selectedObject).length > 1
              ? `${Object.keys(selectedObject).length} items selected`
              : Object.keys(selectedObject).length === 1
                ? `1 item selected.`
                : 'Select items to upload.'
          }
          fileToSave={fileToSave}
          selectedObject={selectedObject}
          resetSelection={resetSelection}
          initialConnection={initialConnection}
        />
      )}
    </div>
  );
};
