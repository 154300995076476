import { APIBaseChronos } from 'api/hosts';
import { QueryEntity } from 'api/types';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';
import { Case } from 'types';

export const useGetCase = (caseId: string | undefined) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<Case>([QueryEntity.Case, caseId], () => {
    return fetch(`${APIBaseChronos}/api/case/${caseId}`, fetchConfigGET)
      .then((res) => res.json())
      .catch((err) => {
        console.error(err);
      });
  });
};
