import { APIBaseChronos } from 'api/hosts';
import { QueryEntity } from 'api/types';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useMutation, useQueryClient } from 'react-query';

interface DeleteCaseRequest {
  caseId: string;
  isSuperAdmin: boolean;
}

export interface DeleteCaseResponse {
  success: boolean;
  notCreator?: boolean;
}

export const useDeleteCase = ({
  onError,
  onSuccess,
}: {
  onSuccess: (data: Response) => void;
  onError: VoidFunction;
}) => {
  const { getFetchConfig } = useGetFetchConfig();
  const queryClient = useQueryClient();

  return useMutation<Response, Error, DeleteCaseRequest, unknown>({
    mutationFn: async ({ caseId, isSuperAdmin }: DeleteCaseRequest) => {
      const fetchConfig = getFetchConfig({ method: 'DELETE', data: { isSuperAdmin } });
      const response = await fetch(`${APIBaseChronos}/api/case/${caseId}`, fetchConfig);
      return response;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [QueryEntity.Case] });
      onSuccess(data);
    },
    onError,
  });
};
