import React from 'react';

interface Props {
  isSearching: boolean;
}

export const LoadingTable = ({ isSearching }: Props) => {
  let columns = [
    {
      Header: 'Name',
    },
    {
      Header: 'Size',
    },
    {
      Header: 'Updated',
    },
  ];
  const array = Array.from(Array(6).keys());
  if (isSearching) {
    columns = [...columns, { Header: 'Service' }];
  }

  return (
    <div className="overflow-auto">
      <table className="w-full divide-y divide-gray-200">
        <thead>
          <tr>
            {columns.map((column: any, i: number) => (
              <th
                key={`column-${i}`}
                className={`space-x-6 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 ${
                  i === columns.length - 1 ? 'text-right' : ''
                }`}
              >
                {column.Header}
              </th>
            ))}
          </tr>
        </thead>

        <tbody className="divide-y divide-gray-200 bg-white">
          {array.map((_, i) => (
            <LoadingRow key={i} columns={columns} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const LoadingRow = ({ columns }: { columns: any[] }) => {
  return (
    <tr>
      {columns.map((_, i) => (
        <td
          className={`space-x-6 truncate whitespace-nowrap py-4 text-sm font-medium ${
            i === columns.length - 1 ? 'text-right' : ''
          }`}
          key={`cell-${i}`}
          style={{ maxWidth: 80 }}
        >
          <span className="animate-pulse rounded-sm bg-gray-200 px-16 py-0"></span>
        </td>
      ))}
    </tr>
  );
};

export default LoadingTable;
