import { ButtonHTMLAttributes, useRef, useState } from 'react';

import { faCheck, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StageSpinner } from 'react-spinners-kit';

interface EditableFieldProps {
  isLoading: boolean;
  value: string;
  onSave: (value: string) => void;
  onFieldClick?: VoidFunction;
}

const EditableField = ({ value: initialValue, onSave, isLoading, onFieldClick }: EditableFieldProps) => {
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState(initialValue);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const autoResize = (ref: React.RefObject<HTMLTextAreaElement>) => {
    if (ref.current) {
      ref.current.style.height = '28px'; // Reset the height to its initial value
      ref.current.style.height = `${ref.current.scrollHeight}px`;
    }
  };

  const togleEditMode: ButtonHTMLAttributes<HTMLButtonElement>['onClick'] = (e) => {
    e.stopPropagation();
    if (!isEdit) {
      setIsEdit(true);
      setTimeout(() => {
        autoResize(textAreaRef);
      }, 0);
    } else {
      setIsEdit(false);
      onSave(value);
    }
  };

  const handleChangeFieldValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    autoResize(textAreaRef);
  };

  if (isLoading) {
    return <StageSpinner className="m-auto" size={28} color={'#4161FF'} />;
  }

  if (isEdit) {
    return (
      <div className="flex w-full flex-row items-center">
        <textarea
          ref={textAreaRef}
          className="w-full resize-none overflow-hidden rounded-md pl-2"
          onChange={handleChangeFieldValue}
          onClick={(e) => e.stopPropagation()}
          value={value}
          style={{
            outline: 'none',
            border: '1px solid rgba(0,0,0,0.1)',
            minWidth: '100px',
            width: '100%',
            height: '28px',
          }}
        />
        <button className="ml-2 text-sm" onClick={togleEditMode}>
          <FontAwesomeIcon icon={faCheck} className="text-gray-700" />
        </button>
      </div>
    );
  }

  return (
    <div className="flex items-center">
      <p className={onFieldClick && 'cursor-pointer'} onClick={onFieldClick}>
        {value}
      </p>
      <button className="ml-2 text-sm" onClick={togleEditMode}>
        <FontAwesomeIcon
          icon={faPencil}
          className="text-gray-700 transition-colors hover:text-gray-300"
          aria-label="edit"
        />
      </button>
    </div>
  );
};

export default EditableField;
