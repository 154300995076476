import { Fragment, useEffect, useRef, useState } from 'react';

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { trackCustomEvent } from 'analytics/Mixpanel';
import { APIBaseChronos } from 'api/hosts';
import useUpdateChronologyFact from 'api/mutations/useUpdateChronologyFact';
import useVerifyChronologyEvent from 'api/mutations/useVerifyChronologyFact';
import Button from 'components/atoms/Button';
import CalendarEditPopup from 'components/molecules/CalendarPopup';
import EditableField from 'components/molecules/EditableField';
import { TooltipStyles } from 'constants/styles';
import moment from 'moment';
import { Tooltip } from 'react-tooltip';
import { ChronologyFact } from 'types';

import { useDocumentNavigation } from '../../../FactsEditor/utils/navigationUtils';

interface ChronologyTitleProps {
  open: boolean;
  caseId: string;
  fact: ChronologyFact;
  factText: string;
  dateText: string;
  updateDate: (v: string) => void;
}

interface UnverifyPopUpProps {
  email: string;
  isOpen: boolean;
  onClose: () => void;
  unVerify: () => void;
  fact: ChronologyFact;
}

const UnverifyPopUp: React.FC<UnverifyPopUpProps> = ({ email, isOpen, onClose, unVerify, fact }) => {
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  const handleUnVerify = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    trackCustomEvent('Fact unverified', { factId: fact.id, chronologyId: fact.chronologyId });
    onClose();
    unVerify();
  };

  return (
    <div
      ref={popupRef}
      className="absolute bottom-[-20px] left-2 z-10 flex w-52 flex-col gap-2 rounded border bg-white pt-3 shadow-md"
    >
      <p className="px-4 text-xs">
        <b>Verified by:</b> {email ? email.slice(0, 18) + (email.length > 18 ? '...' : '') : null}
      </p>
      <div className="border-t bg-gray-50 pb-2">
        <div className="mt-2 flex justify-end px-4">
          <Button text="Unverify" type="delete" onClick={handleUnVerify} size="xs" rounded="base" />
        </div>
      </div>
    </div>
  );
};

const ChronologyTitle = ({ open, caseId, fact, dateText, updateDate, factText }: ChronologyTitleProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [verified, setVerified] = useState(fact.verifiedById !== null);
  const [verifiedDate, setVerifiedDate] = useState(fact.verifiedDate);
  const [verifiedBy, setVerifiedBy] = useState(fact.verifiedBy?.email || null);

  const { goToDocReference } = useDocumentNavigation();
  const { mutate: updateChronologyFact, isLoading: isUpdatingChronologyFact } = useUpdateChronologyFact({
    analyticsLocation: 'Chronology',
    caseId: caseId,
  });

  const { updateVerifiedMutation } = useVerifyChronologyEvent(caseId);
  const handleVerifyChronology = (verify: boolean) => {
    setVerified(verify);
    setVerifiedDate(new Date());
    setVerifiedBy(fact.verifiedBy?.email || 'you');
    updateVerifiedMutation.mutate({ factId: fact.id, verified: verify });
  };

  const handleUpdateFactText = (value: string) => {
    updateChronologyFact({
      field: 'description',
      value,
      factId: fact.id,
      chronologyId: fact.chronologyId,
    });
  };

  return (
    <div className="flex w-full items-center justify-between font-normal">
      <div className="w-full p-[6px] px-2 pl-2">
        {/* Date Section */}
        <div className="flex w-full justify-between">
          <div className="flex items-center gap-2 text-xs font-semibold">
            <CalendarEditPopup
              dateText={dateText}
              entryFieldKey="fact_date_text"
              updateDate={updateDate}
              compressedVersion={true}
              endpointToUpdate={`${APIBaseChronos}/client/case/chronology/${fact.id}`}
              location="Chronology"
            />

            {dateText ? dateText : '(Date Unknown)'}
          </div>
          {/* Documents */}
          <div className="text-xs text-blue-600">
            {fact.locations.map((location, index) => {
              const doc = location.doc;
              if (!doc) return null;
              if (index === 1) return <Fragment key={doc.file.name}>...</Fragment>;
              if (index > 1) return <Fragment key={doc.file.name}></Fragment>;

              return (
                <span key={doc.file.name}>
                  <span
                    data-tooltip-id={`fact-document-tooltip-${fact.id}-${doc.id}`}
                    data-tooltip-content={doc.file.name}
                    onClick={(e) => {
                      e.stopPropagation();
                      goToDocReference(doc.id, fact.fact.id, location.boundingBoxes?.[0]?.pageNumber || 1);
                    }}
                  >
                    {doc.file.name ? doc.file.name.slice(0, 20).trim() + '...' : ''}
                  </span>
                  {doc.type && (
                    <span className="mx-1 rounded-sm bg-brandTertiary bg-opacity-20 px-1 text-gray-600">
                      {doc.type}
                    </span>
                  )}
                  {fact.locations.length > 1 && index < fact.locations.length - 1 ? <>, </> : <></>}

                  <Tooltip opacity={1} id={`fact-document-tooltip-${fact.id}-${doc.id}`} style={TooltipStyles} />
                </span>
              );
            })}
          </div>
        </div>

        {/* Fact description */}
        <div className="flex justify-between gap-1">
          <p className="w-5/6 pl-2 pt-1 text-xs text-gray-900">
            {open ? (
              <EditableField value={factText} onSave={handleUpdateFactText} isLoading={isUpdatingChronologyFact} />
            ) : factText.length > 160 ? (
              factText.slice(0, 160) + '...'
            ) : (
              factText
            )}
          </p>

          {verified ? (
            <div className="relative flex items-center gap-2">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setShowTooltip(!showTooltip);
                }}
                className="flex h-6 items-center gap-1 rounded border border-green-700 bg-green-50 px-2 py-1 text-xs text-gray-800"
              >
                <FontAwesomeIcon icon={faCheckCircle} className="text-green-700" />
                Verified
              </button>

              <div className="text-xs text-gray-600">
                {verifiedDate ? moment(verifiedDate).format('MMM DD, YYYY') : null}
              </div>
              <UnverifyPopUp
                email={verifiedBy || ''}
                isOpen={showTooltip}
                onClose={() => setShowTooltip(false)}
                unVerify={() => handleVerifyChronology(false)}
                fact={fact}
              />
            </div>
          ) : (
            <Button
              text="Verify"
              onClick={(e) => {
                e.stopPropagation();
                trackCustomEvent('Fact verified', { factId: fact.id, chronologyId: fact.chronologyId });
                handleVerifyChronology(true);
              }}
              className="h-6 rounded bg-buttonPrimary px-3 py-1 text-xs text-white shadow hover:bg-buttonPrimary-hover"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChronologyTitle;
