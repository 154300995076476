import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HighlightArea } from '@react-pdf-viewer/highlight';
import Modal from 'components/molecules/Modals/Settings';

import FactAdder from '../../../DataView/FactsEditor/FactsEditorToolbar/components/FactAdder';

interface FactAdderModalProps {
  docId: string;
  isOpen: boolean;
  onSuccess: VoidFunction;
  onClose: (bool: boolean) => void;
  selectedText?: string;
  highlightAreas?: HighlightArea[];
  caseId: string;
}

const FactAdderModal = ({
  docId,
  isOpen,
  onSuccess,
  onClose,
  selectedText,
  highlightAreas,
  caseId,
}: FactAdderModalProps) => {
  return (
    <Modal
      title={
        <div className="flex items-center gap-2 font-semibold text-blue-500">
          <FontAwesomeIcon icon={faPlusCircle} className="" />
          Add Fact
        </div>
      }
      content={
        <FactAdder
          docs={[]}
          handleNewFactCreated={onSuccess}
          docId={docId}
          caseId={caseId}
          sourceText={selectedText}
          highlightAreas={highlightAreas}
        />
      }
      isOpen={isOpen}
      size="small"
      handleClose={() => onClose(false)}
    />
  );
};

export default FactAdderModal;
