import { APIBaseChronos } from 'api/hosts';
import { GetCaseOverviewRes } from 'api/types/queries';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';

const useGetCaseOverview = (caseId: string | null = '') => {
  const { fetchConfigGET } = useGetFetchConfig();

  const getCaseOverview = async (): Promise<GetCaseOverviewRes> => {
    const response = await fetch(`${APIBaseChronos}/api/case/${caseId}/overview`, fetchConfigGET);
    if (!response.ok) {
      throw new Error('Fetching case overview failed');
    }

    return response.json();
  };

  return useQuery(['caseOverview', caseId], getCaseOverview, {
    refetchOnWindowFocus: false,
  });
};

export default useGetCaseOverview;
