import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useMutation } from 'react-query';

export const useDeleteChronologyFact = (caseId: string) => {
  const { getFetchConfig } = useGetFetchConfig();

  return useMutation(async (factId: string) => {
    const fetchConfig = getFetchConfig({ method: 'DELETE' });
    return fetch(`${APIBaseChronos}/api/case/${caseId}/chronology/fact/${factId}`, fetchConfig);
  });
};
