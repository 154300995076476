import { APIBaseChronos } from 'api/hosts';
import { QueryEntity } from 'api/types';
import { FetchFactsByDocRes } from 'api/types/queries';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { createFilterParams } from 'helpers/createFilterParams';
import { useMutation, useQueryClient } from 'react-query';
import { Filters } from 'types';

const BATCH_SIZE = 250;

const useToggleAll = (caseId: string) => {
  const { getFetchConfig } = useGetFetchConfig();
  const queryClient = useQueryClient();

  // Define the mutation
  return useMutation({
    onMutate: async ({ toggleValue }) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: [QueryEntity.CaseFacts, { caseId }] });

      // Snapshot the previous value
      const previousFactsRes = queryClient.getQueryData<FetchFactsByDocRes>([QueryEntity.CaseFacts, { caseId }]);

      // Optimistically update to the new value
      queryClient.setQueryData<FetchFactsByDocRes | undefined>([QueryEntity.CaseFacts, { caseId }], (old) => {
        if (old) {
          return {
            ...old,
            includedFactsCount: toggleValue ? old.totalFactsCount : 0,
            facts: old.facts.map((fact) => ({
              ...fact,
              included: toggleValue,
            })),
          };
        }
      });

      // Return a context object with the snapshotted value
      return { previousFactsRes };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, { caseId }, context) => {
      queryClient.setQueryData([QueryEntity.CaseFacts, { caseId }], context?.previousFactsRes);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryEntity.CaseFacts, { caseId }] });
      queryClient.invalidateQueries({ queryKey: [QueryEntity.IncludedFactsCount, { caseId }] });
    },
    mutationFn: async ({
      caseId,
      toggleValue,
      filters,
      factIds,
    }: {
      caseId: string;
      toggleValue: boolean;
      filters: Filters;
      factIds: string[];
    }) => {
      const fetchConfig = getFetchConfig({
        method: 'PATCH',
      });

      // Send the fact ids in batches
      const sendBatch = async (filters: Filters, factIds?: string[]) => {
        const filterParams = createFilterParams(filters);
        const body = {
          factIds,
        };
        fetchConfig.body = JSON.stringify(body);

        const response = await fetch(
          `${APIBaseChronos}/api/case/${caseId}/fact/toggle-all-included?${filterParams}&included=${toggleValue}`,
          fetchConfig,
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
      };

      if (factIds && factIds.length > BATCH_SIZE) {
        const batches = [];
        for (let i = 0; i < factIds.length; i += BATCH_SIZE) {
          const factIdsBatch = factIds.slice(i, i + BATCH_SIZE);
          batches.push(sendBatch(filters, factIdsBatch));
        }
        return Promise.all(batches);
      } else {
        return sendBatch(filters);
      }
    },
  });
};

export default useToggleAll;
