import { Navigate, Route, Routes } from 'react-router-dom';

import CaseCreator from './CaseCreator';
import CaseEditor from './CaseEditor';
import CasesExplorer from './CasesExplorer';
import Guide from './Guide';
import KimQA from './KimQA';
import MatterCreator from './MatterCreator';
import MattersExplorer from './MattersExplorer';
import UsageDashboard from './UsageDashboard';

const Chronos = () => {
  return (
    <Routes>
      <Route path="matters" element={<MattersExplorer />} />
      <Route path="usage-dashboard" element={<UsageDashboard />} />
      <Route path="explore" element={<CasesExplorer />} />
      <Route path="case-creator" element={<CaseCreator />} />
      <Route path="matter-creator" element={<MatterCreator />} />
      <Route path="matter-editor/:matterId" element={<MatterCreator isEditMode={true} />} />
      <Route path="case-editor/*" element={<CaseEditor />} />
      <Route path="guide" element={<Guide />} />
      <Route path="kim-qa" element={<KimQA />} />
      <Route path="*" element={<Navigate to="/app/chronos/matters" />} />
    </Routes>
  );
};

export default Chronos;
