import { useCallback, useEffect, useRef, useState } from 'react';

import PDFViewer from 'components/organisms/PDFViewer';
import { useLocation, useNavigate } from 'react-router-dom';
import { BoundingBox } from 'types';

import { useGetDocPresignedURL } from '../../../../../../api/queries/useGetDocPresignedURL';

interface HighlightCoordinate {
  factId: string;
  height: number;
  left: number;
  pageIndex: number;
  top: number;
  width: number;
}

const DocViewer = ({
  caseId,
  factId,
  docId,
  coordinates,
}: {
  caseId: string;
  docId: string;
  factId: string;
  coordinates: BoundingBox[];
}) => {
  // State
  const navigate = useNavigate();
  const location = useLocation();

  const viewerRef = useRef(null);
  const [minPage, setMinPage] = useState(1);
  const [highlightCoordinates, setHighlightCoordinates] = useState<HighlightCoordinate[]>([]);

  // Data fetching
  const { data: docUrl } = useGetDocPresignedURL(caseId, docId);

  // Event handlers
  const handleCloseDocDetailView = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('chunkId');
    searchParams.delete('docId');
    navigate(location.pathname + '?' + searchParams.toString(), { replace: true });
  }, [navigate, location.pathname, location.search]);

  useEffect(() => {
    if (coordinates && coordinates.length > 0) {
      setMinPage(coordinates[0].pageNumber);
      setHighlightCoordinates(
        coordinates.map((coordinate: BoundingBox) => {
          return {
            factId: factId,
            height: coordinate.height * 100,
            left: coordinate.left * 100,
            pageIndex: coordinate.pageNumber - 1,
            top: coordinate.top * 100,
            width: coordinate.width * 100,
          };
        }) || [],
      );
    }
  }, [coordinates, factId]);

  return (
    <div className="flex h-full flex-col">
      <div className="flex-grow overflow-hidden">
        <div ref={viewerRef} className="h-full">
          <PDFViewer
            fileUrl={docUrl}
            initialPage={minPage}
            highlights={highlightCoordinates}
            handleClosePDFViewer={handleCloseDocDetailView}
            closeButtonText="Close"
            defaultZoom={1}
            type="inline"
          />
        </div>
      </div>
    </div>
  );
};

export default DocViewer;
