import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useMutation } from 'react-query';

interface CompleteGuidePayload {
  userId: string;
  guideName: string;
}

export function useCompleteGuide() {
  const { getFetchConfig } = useGetFetchConfig();

  return useMutation<void, Error, CompleteGuidePayload>(
    async ({ userId, guideName }: { userId: string; guideName: string }) => {
      const url = new URL(`${APIBaseChronos}/api/user/${userId}/guide`);

      await fetch(url.toString(), {
        ...getFetchConfig({ method: 'POST' }),
        body: JSON.stringify({ completedGuideName: guideName }),
      });
    },
  );
}
