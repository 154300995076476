export const MentionsInputStyle = {
  control: {
    backgroundColor: '#fff',
    fontSize: 14,
    fontWeight: 'normal',
    maxHeight: '200px',
  },
  highlighter: {
    maxHeight: '200px',
  },
};

export const MentionsInputStyleKim = {
  ...MentionsInputStyle,
  input: {
    margin: 0,
    padding: 0,
    overflow: 'auto',
    height: 'auto',
    maxHeight: '200px',
  },
  suggestions: {
    zIndex: 20,
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 12,
      maxHeight: '180px',
      overflow: 'auto',
      borderRadius: '4px',
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
};

export const MentionsInputStyleComments = {
  ...MentionsInputStyle,
  input: {
    padding: '4px 8px',
    fontSize: '12px',
  },
  suggestions: {
    zIndex: 20,
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 12,
      maxHeight: '120px',
      overflow: 'auto',
      borderRadius: '4px',
    },
    item: {
      padding: '5px 8px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
};
