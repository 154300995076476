import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';
import { Artifact } from 'types';

const useDownloadAnswer = (caseId: string, threadId: string, artifact?: Artifact) => {
  const { fetchConfigGET } = useGetFetchConfig();

  const downloadAnswer = async () => {
    let fetchUrl = `${APIBaseChronos}/api/case/${caseId}/thread/${threadId}/download`;
    if (artifact) {
      fetchUrl += `?bucket=${artifact.s3_bucket}&key=${artifact.s3_key}`;
    }

    const response = await fetch(fetchUrl, fetchConfigGET);
    if (!response.ok) {
      throw new Error('Downloading answer failed');
    }
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.href = url;
    if (artifact) {
      a.download = `${artifact.file_name}.${artifact.extension}`;
    } else {
      a.download = `Kim_Answer_Output_${threadId[0] + threadId[1] + threadId[2]}.docx`;
    }
    a.click();
    window.URL.revokeObjectURL(url);
    return { success: true };
  };

  const { isFetching, data, refetch, error } = useQuery(['downloadAnswer', threadId], downloadAnswer, {
    cacheTime: 0,
    enabled: false,
  });

  return {
    isFetching,
    data,
    refetch,
    error,
  };
};

export default useDownloadAnswer;
