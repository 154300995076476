import { APIBaseChronos } from 'api/hosts';
import { QueryEntity } from 'api/types';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useInfiniteQuery } from 'react-query';
import { File } from 'types';

interface FilesResponse {
  files: File[];
  pageCount: number;
  currentPage: number;
  totalCount: number;
}

const useGetFiles = (caseId: string) => {
  const { fetchConfigGET } = useGetFetchConfig();

  const fetchFiles = async (pageParam: number): Promise<FilesResponse> => {
    const response = await fetch(`${APIBaseChronos}/api/files/${caseId}?page=${pageParam}`, fetchConfigGET);
    if (!response.ok) {
      throw new Error('Fetching files failed');
    }
    return response.json();
  };

  return useInfiniteQuery<FilesResponse>({
    queryKey: [QueryEntity.Files, caseId],
    queryFn: async ({ pageParam = 1 }) => {
      return await fetchFiles(pageParam);
    },
    keepPreviousData: true,
    getNextPageParam: (lastPage) => {
      return lastPage.pageCount > lastPage.currentPage ? lastPage.currentPage + 1 : null;
    },
  });
};

export default useGetFiles;
