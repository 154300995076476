import { useState } from 'react';

import { Button as NewButton } from 'components/atoms/new-button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'components/molecules/dropdown-menu';
import Modal from 'components/molecules/Modals/Settings';
import { ArrowLeftRight, Users } from 'lucide-react';

import TransferCase from './TransferCase';

const Share = ({ caseId }: { caseId: string }) => {
  const [matterShareModalOpen, setMatterShareModalOpen] = useState(false);

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <NewButton size={'sm'} className="border bg-white px-2 shadow-sm hover:cursor-pointer hover:bg-gray-100">
            <Users size={20} />
            Share
          </NewButton>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="mx-4 w-56 bg-white">
          <DropdownMenuLabel>Options</DropdownMenuLabel>
          <DropdownMenuSeparator className="bg-gray-200" />
          <DropdownMenuGroup>
            <DropdownMenuItem
              onClick={() => {
                setMatterShareModalOpen(true);
              }}
              className="rounded hover:cursor-pointer hover:bg-gray-100"
            >
              <span>Move to a different matter</span>
              <ArrowLeftRight size={16} />
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>

      <Modal
        title={<div className="text-lg font-semibold">Transfer Case</div>}
        isOpen={matterShareModalOpen}
        handleClose={() => setMatterShareModalOpen(false)}
        size="small"
        content={<TransferCase caseId={caseId} setMatterShareModalOpen={setMatterShareModalOpen} />}
      />
    </>
  );
};

export default Share;
