export const allowedFileTypes = [
  '.csv',
  '.doc',
  '.docx',
  '.eml',
  '.fax',
  '.html',
  '.img',
  '.jpeg',
  '.jpg',
  '.mht',
  '.msg',
  '.pdf',
  '.png',
  '.ppt',
  '.pptx',
  '.rtf',
  '.tif',
  '.txt',
  '.xls',
  '.xlsx',
];
