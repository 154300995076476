import Select from 'react-select';
import { DocIds } from 'types';

interface OptionType {
  value: string | boolean;
  label: string;
}

interface FilterPopupProps {
  selectedOptions: OptionType[];
  setSelectedOptions: (v: OptionType[]) => void;
  allDocuments: DocIds[];
  className?: string;
}

const FilterPopup = ({ selectedOptions, setSelectedOptions, allDocuments = [], className = '' }: FilterPopupProps) => {
  // Create a map of unique documents using reduce
  const uniqueDocumentsMap = allDocuments.reduce<Record<string, DocIds>>((acc, doc) => {
    acc[doc.id] = doc;
    return acc;
  }, {});

  const uniqueDocuments = Object.values(uniqueDocumentsMap);

  const options = uniqueDocuments.map((document) => ({
    value: document.id,
    label: document.file.name.split('.').filter(Boolean)[0],
  }));

  return (
    <Select
      className={className}
      isMulti
      value={selectedOptions}
      onChange={(selected) => setSelectedOptions(selected as OptionType[])}
      options={options}
      placeholder="Select documents"
    />
  );
};

export default FilterPopup;
