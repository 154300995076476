import React, { FC, useEffect, useState } from 'react';

import { ApideckVault } from '@apideck/vault-js';
import useSWR from 'swr';

import FilesContainer from './FilesContainer';
import SelectConnection from './SelectConnection';
import { Connection } from '../types/Connection';
import { File } from '../types/File';
import { ToastProvider } from '../utils/useToast';

export interface Props {
  appId: string;
  consumerId: string;
  jwt: string;
  onSelect: (file: File, selectedSource: string) => any;
  onConnectionSelect: (connection: Connection) => any;
  title?: string;
  subTitle?: string;
  fileToSave: File;
  selectedObject: Record<string, any>;
  resetSelection: () => void;
  initialConnection?: string;
}

export const ModalContent: FC<Props> = ({
  appId,
  consumerId,
  jwt,
  onSelect,
  onConnectionSelect,
  title,
  subTitle,
  fileToSave,
  selectedObject,
  resetSelection,
  initialConnection,
}) => {
  const [connection, setConnection] = useState<Connection>();
  const [isLoadingVault, setIsLoadingVault] = useState(false);

  const getConnections = async (url: string) => {
    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        'x-apideck-consumer-id': consumerId,
        'x-apideck-app-id': appId,
        'x-apideck-auth-type': 'JWT',
        Authorization: `Bearer ${jwt}`,
      },
    });
    return await response.json();
  };

  const { data, error, mutate } = useSWR(
    `https://unify.apideck.com/vault/connections?api=file-storage`,
    getConnections,
    {
      shouldRetryOnError: false,
    },
  );
  const isLoading = !data && !error;
  const hasError = data?.error || error;
  const connections = data?.data;
  const callableConnections: Array<Connection> = connections?.filter((connection: Connection) => connection.state === 'callable') ?? [];

  useEffect(() => {
    if (!connection && callableConnections?.length) {
      if (initialConnection && callableConnections.find(c => c.service_id === initialConnection)) {
        setConnection(callableConnections.find(c => c.service_id === initialConnection));
      } else {
        setConnection(callableConnections[0]);
      }
    }
  // eslint-disable-next-line
  }, [setConnection, callableConnections]);

  useEffect(() => {
    if (connection) {
      onConnectionSelect(connection);
    }

  // eslint-disable-next-line
  }, [connection]);

  const modalHeight = document.getElementById('modal-component')?.clientHeight;

  return (
    <div className="h-modal relative -m-6 bg-white sm:rounded-lg" style={{ height: '34rem' }}>
      <div className="flex items-center justify-between px-4 py-5 sm:px-6">
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">{title}</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            {hasError ? (
              <span className="mb-2 text-red-600">{hasError}</span>
            ) : (
              <span className="text-gray-700 dark:text-gray-400">
                {connection ? subTitle : 'No connector selected.'}
              </span>
            )}
          </p>
        </div>
        <SelectConnection
          jwt={jwt}
          connections={connections}
          connection={connection}
          setConnection={setConnection}
          mutate={mutate}
          isLoading={isLoading}
          initialConnection={initialConnection}
        />
      </div>
      <div
        className="overflow-y-auto border-t border-gray-200 px-4 py-5 sm:px-6"
        style={{
          height: typeof window !== 'undefined' && modalHeight ? modalHeight - 70 : 'calc(100% - 70px)',
        }}
      >
        {connection ? (
          <ToastProvider>
            <FilesContainer
              appId={appId}
              consumerId={consumerId}
              jwt={jwt}
              onSelect={onSelect}
              connections={callableConnections}
              connection={connection}
              setConnection={setConnection}
              fileToSave={fileToSave}
              selectedObject={selectedObject}
              resetSelection={resetSelection}
            />
          </ToastProvider>
        ) : !callableConnections?.length && !isLoading ? (
          <div className="empty flex h-96 items-center justify-center rounded-lg border-2 border-dashed border-gray-200">
            <div className="text-center">
              {hasError === 'Unauthorized' ? (
                <p className="text-sm text-gray-700">Your session is invalid</p>
              ) : (
                <>
                  <button
                    className={`text-indigo-600 hover:text-indigo-900 ${isLoadingVault && 'animate-pulse'}`}
                    onClick={() => {
                      setIsLoadingVault(true);
                      ApideckVault.open({
                        token: jwt,
                        unifiedApi: 'file-storage',
                        onReady: () => setIsLoadingVault(false),
                        onClose: () => mutate(),
                        showAttribution: false,
                      });
                    }}
                  >
                    {isLoadingVault ? 'Loading...' : 'Open Vault'}
                  </button>{' '}
                  to add file storage connectors
                </>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
