import { trackCustomEvent } from 'analytics/Mixpanel';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { APIBaseChronos } from '../hosts';
import { QueryEntity } from '../types';
import useGetFetchConfig from '../useGetFetchConfig';

interface UseUpdateFact {
  field: string;
  value: string;
  factId: string;
  caseId: string;
}

const useUpdateFact = ({ analyticsLocation }: { analyticsLocation: string }) => {
  const { getFetchConfig } = useGetFetchConfig();
  const queryClient = useQueryClient();

  return useMutation(async ({ field, value, factId, caseId }: UseUpdateFact) => {
    const fetchConfig = getFetchConfig({ method: 'PATCH', data: { [field]: value } });
    return fetch(`${APIBaseChronos}/api/case/${caseId}/fact/${factId}`, fetchConfig)
      .then((res) => {
        trackCustomEvent(`Field updated ${field} - ${analyticsLocation}`, { caseId, factId });
        queryClient.invalidateQueries({ queryKey: [QueryEntity.CaseFacts, { caseId }] });
      })
      .catch((err) => {
        toast.error('Failed to update field');
      });
  });
};

export default useUpdateFact;
