import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useMutation } from 'react-query';

const useCreateThread = (caseId: string) => {
  const { getFetchConfig } = useGetFetchConfig();

  const createThread = async (question: string) => {
    const fetchConfig = getFetchConfig({
      method: 'POST',
      data: {
        question,
      },
    });

    const response = await fetch(`${APIBaseChronos}/api/case/${caseId}/thread`, fetchConfig);
    if (!response.ok) {
      throw new Error('Creating thread failed');
    }
    return await response.json();
  };

  return useMutation((question: string) => createThread(question));
};

export default useCreateThread;
