import React from 'react';

import { ReactComponent as ReactInfo } from 'assets/icons/info.svg';
import { TooltipStyles } from 'constants/styles';
import { Tooltip } from 'react-tooltip';

interface StatisticItemProps {
  title: string;
  value: string | number | null;
  isFetching: boolean;
  tooltipText?: string;
}

const formatValue = (value: string | number | null) => {
  if (value === null || value === undefined) {
    return <span className="text-5xl">0</span>;
  }
  // Convert value to a string if it is a number
  const stringValue = value.toString();

  // Format the number with commas
  const formattedValue = stringValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Determine the text size based on the length of the value
  let textSizeClass = 'text-5xl';
  if (formattedValue.length > 10) {
    textSizeClass = 'text-xl';
  } else if (formattedValue.length > 6) {
    textSizeClass = 'text-2xl';
  }

  // Return the formatted value wrapped in a span with the appropriate class
  return <span className={`${textSizeClass}`}>{formattedValue}</span>;
};

const StatisticItem: React.FC<StatisticItemProps> = ({ title, value, isFetching, tooltipText }) => (
  <div className="flex flex-1 flex-col items-stretch justify-between rounded-lg bg-white p-4">
    <div className="mb-5 flex flex-row items-center gap-1 text-sm text-gray-400">
      {title}
      {tooltipText && (
        <>
          <ReactInfo
            data-tooltip-id={`${title}-tooltip`}
            data-tooltip-content={tooltipText}
            className="mr-2 cursor-pointer text-sm text-slate-400"
          />
          <Tooltip id={`${title}-tooltip`} style={TooltipStyles} />
        </>
      )}
    </div>
    <div className="font-semibold text-black">
      {isFetching || value === null ? (
        <div className="h-12 w-28 animate-pulse rounded-2xl bg-gray-100" />
      ) : (
        formatValue(value)
      )}
    </div>
  </div>
);

export default StatisticItem;
