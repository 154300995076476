import { APIBaseChronos } from 'api/hosts';
import { QueryEntity } from 'api/types';
import { GetChronologyRes } from 'api/types/queries';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';

export const useGetChronology = ({
  chronologyId,
  caseId,
  pageIndex,
  pageSize,
}: {
  chronologyId: string;
  caseId: string;
  pageIndex: number;
  pageSize: number;
}) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<GetChronologyRes>([QueryEntity.Chronology, { chronologyId }, { pageIndex, pageSize }], () => {
    return fetch(
      `${APIBaseChronos}/api/case/${caseId}/chronology/${chronologyId}?page=${pageIndex + 1}&pageSize=${pageSize}`,
      fetchConfigGET,
    ).then((res) => {
      return res.json();
    });
  });
};
