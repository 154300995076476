export interface ApiResponse {
  msg: string;
  type: string;
}

export interface Pagination {
  currentPage: number;
  filteredPages: number | null;
  pageSize: number;
  processedCount: number;
  totalCount: number;
  totalPages: number;
}

export enum QueryEntity {
  ApideckSession,
  ApideckAllConnections,
  OrgUsageData,
  Case,
  Matter,
  CaseDoc,
  CaseFacts,
  CaseEventCoordinates,
  Chronology,
  Chronologies,
  RecentUserActivity,
  Files,
  IncludedFactsCount,
  DocTotals,
  DocIds,
  Threads,
  Summary,
  Users,
  KeyFacts,
  Messages,
  PresignedPost,
  PresignedGetDocument,
  MatterUsers,
}
