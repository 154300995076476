import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Viewer } from '@react-pdf-viewer/core';
import {
  HighlightArea,
  HighlightPlugin,
  highlightPlugin,
  RenderHighlightsProps,
  Trigger,
} from '@react-pdf-viewer/highlight';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import isTruthy from 'helpers/isTruthy';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';

import { FloatingControls, HeaderControls } from './Controls';

interface HighlightAreaWithFactId extends HighlightArea {
  factId: string;
}

interface PDFViewerProps {
  fileUrl: string | null | undefined;
  initialPage: number;
  handleClosePDFViewer?: (() => void) | null;
  highlights?: HighlightAreaWithFactId[];
  highlightInstance?: HighlightPlugin;
  highlightToAddFactInstance?: HighlightPlugin;
  closeButtonText?: string;
  defaultZoom?: number;
  title?: string;
  type: 'full' | 'inline';
}

const PDFViewer = ({
  title,
  type,
  fileUrl,
  initialPage,
  highlightInstance,
  highlightToAddFactInstance,
  highlights,
  handleClosePDFViewer,
  closeButtonText = 'Back',
  defaultZoom = 1.1,
}: PDFViewerProps) => {
  let highlightPluginInstance = highlightInstance;
  if (!highlightInstance && highlights) {
    const renderHighlights = (props: RenderHighlightsProps) => {
      return (
        <div>
          {highlights
            .filter((area) => area.pageIndex === props.pageIndex)
            .map((area, idx) => (
              <div
                key={idx}
                className={`highlight-area z-10 cursor-pointer bg-yellow-300`}
                style={Object.assign({}, props.getCssProperties(area, props.rotation), {
                  pointerEvents: 'auto',
                  opacity: 0.3,
                  mixBlendMode: 'darken',
                })}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            ))}
        </div>
      );
    };

    highlightPluginInstance = highlightPlugin({
      renderHighlights: renderHighlights,
      trigger: Trigger.None,
    });
  }

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const zoomPluginInstance = zoomPlugin();
  const { ZoomIn, ZoomOut } = zoomPluginInstance;

  const { CurrentPageInput, GoToPreviousPage, GoToNextPage, GoToFirstPage, GoToLastPage } =
    pageNavigationPluginInstance;

  const plugins = [
    pageNavigationPluginInstance,
    zoomPluginInstance,
    highlightPluginInstance,
    highlightToAddFactInstance,
  ].filter(isTruthy);

  return (
    <>
      <div className="flex h-full flex-col">
        <div
          className={`flex items-center gap-4 border-b px-2 py-2 shadow-sm ${
            type === 'inline' ? 'justify-between' : 'justify-start'
          }`}
        >
          <div
            className="flex cursor-pointer items-center gap-2 rounded-full border bg-gray-100 px-2 hover:bg-gray-200"
            onClick={() => handleClosePDFViewer && handleClosePDFViewer()}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="h-3 w-3 py-1 xl:py-0" />
            <p className="hidden xl:block">{closeButtonText}</p>
          </div>

          {type === 'inline' && (
            <HeaderControls
              GoToFirstPage={GoToFirstPage}
              GoToPreviousPage={GoToPreviousPage}
              CurrentPageInput={CurrentPageInput}
              GoToNextPage={GoToNextPage}
              GoToLastPage={GoToLastPage}
              ZoomIn={ZoomIn}
              ZoomOut={ZoomOut}
            />
          )}

          {type === 'full' && title && (
            <div className="font-medium">{title.length > 60 ? title.slice(0, 60) + '...' : title}</div>
          )}
        </div>
        <div className="relative h-full overflow-hidden pt-2">
          {fileUrl && (
            <Viewer
              fileUrl={fileUrl}
              plugins={plugins}
              initialPage={initialPage - 1} // package 0 indexes pages
              defaultScale={defaultZoom}
            />
          )}

          {type === 'full' && (
            <FloatingControls
              GoToFirstPage={GoToFirstPage}
              GoToPreviousPage={GoToPreviousPage}
              CurrentPageInput={CurrentPageInput}
              GoToNextPage={GoToNextPage}
              GoToLastPage={GoToLastPage}
              ZoomIn={ZoomIn}
              ZoomOut={ZoomOut}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PDFViewer;
