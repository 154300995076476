import { cn } from 'helpers/shadCnUtils';

interface ImagesButtonProps {
  title: string;
  imageSource: Array<string>;
  hoverText: string;
  onClick: () => void;
  blankCount?: number;
}

const ImagesButton = ({ title, imageSource, hoverText, onClick, blankCount = 4 }: ImagesButtonProps) => {
  return (
    <button
      onClick={onClick}
      title={hoverText}
      className={cn(
        'flex w-fit items-center justify-center rounded-2xl border border-dashed border-brandSecondary bg-gray-200 bg-slate-100 text-base font-bold text-white',
      )}
    >
      <div className="flex items-center justify-between p-2.5">
        <div className="gap-1.5 flex">
          {imageSource.length
            ? imageSource.map((source, index) => (
                <img
                  key={index}
                  src={source}
                  alt={title}
                  className="w-8 h-8 rounded"
                />
              ))
            : Array.from({ length: blankCount }).map((_, index) => (
                <div
                  key={index}
                  className="w-8 h-8 rounded bg-gray-500"
                />
              ))}
        </div>
      </div>
    </button>
  );
};

export default ImagesButton;
