import { APIBaseChronos } from 'api/hosts';
import { QueryEntity } from 'api/types';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';
import { Case } from 'types';

interface CasesResponse {
  cases: Case[];
  pageCount: number;
}

export const useCasesQuery = ({
  matterId,
  searchText,
  pageQuery,
}: {
  matterId: string | null;
  searchText: string;
  pageQuery: number;
}) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<CasesResponse>([QueryEntity.Case, { matterId }, searchText, pageQuery], async () => {
    const cleanSearchText = searchText?.replace(/\s+/g, ' ').trim() || '';
    return fetch(
      `${APIBaseChronos}/api/case/cases/${matterId}?search=${cleanSearchText}&page=${pageQuery}`,
      fetchConfigGET,
    ).then((res) => res.json());
  });
};
