import React from 'react';

import { faArrowRight, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { trackCustomEvent } from 'analytics/Mixpanel';
import { useProcessCase } from 'api/mutations/useProcessCase';
import Button from 'components/atoms/Button';
import { Input } from 'components/atoms/input';
import { Label } from 'components/atoms/label';
import { Textarea } from 'components/atoms/textarea';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

interface LightCaseUploaderProps {
  name: string;
  setName: (value: string) => void;
  legalIssues: string;
  setLegalIssues: (value: string) => void;
  uploadDisabled: boolean;
}

const LightCaseUploader = ({ name, setName, legalIssues, setLegalIssues, uploadDisabled }: LightCaseUploaderProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const caseId = searchParams.get('caseId') ?? '';

  const { mutate: processCase } = useProcessCase();

  const handleUpdateCase = () => {
    trackCustomEvent('Light case uploaded', {
      caseId,
    });
    processCase(
      {
        caseId,
        name,
        legalIssues,
      },
      {
        onSuccess: () => {
          navigate(`/app/chronos/case-editor/summary?caseId=${caseId}`);
        },
      },
    );
  };

  return (
    <>
      <div className="mb-4 flex items-center border-b border-gray-100 pb-4">
        <FontAwesomeIcon icon={faPenToSquare} className="mr-3 h-4 w-4 text-gray-600" />
        <h2 className="text-lg font-semibold text-gray-900">Details</h2>
      </div>
      <div className="flex flex-col gap-8">
        <div>
          <Label className="text-sm font-medium text-gray-700">Case Name</Label>
          <Input
            className="rounded-lg border-gray-300"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter a name for your case"
          />
        </div>
        <div>
          <Label className="text-sm font-medium text-gray-700">Case Issues</Label>
          <Textarea
            className="max-h-56 min-h-36 rounded-lg border-gray-300"
            value={legalIssues}
            onChange={(e) => setLegalIssues(e.target.value)}
            placeholder="Outline the key issues you want to explore, either in list format or prose."
          />
        </div>

        <Button
          onClick={handleUpdateCase}
          disabled={uploadDisabled || !legalIssues}
          className="flex items-center justify-center rounded-md bg-brandSecondary px-4 py-2 text-white transition-colors hover:bg-brandSecondary-hover disabled:cursor-not-allowed disabled:opacity-50"
          text="Start Processing"
          iconRight
          icon={<FontAwesomeIcon icon={faArrowRight} className="ml-2 h-4 w-4" />}
        />
      </div>
    </>
  );
};

export default LightCaseUploader;
