import { APIBaseChronos } from 'api/hosts';
import { QueryEntity } from 'api/types';
import { GetFactsReq, GetFactsRes } from 'api/types/queries';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { createFilterParams } from 'helpers/createFilterParams';
import { useQuery } from 'react-query';

function useFactsData({ docId, caseId, searchQuery, filters, page, pageSize }: GetFactsReq) {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<GetFactsRes, Error>(
    [QueryEntity.CaseFacts, { caseId }, { docId, searchQuery, filters, page, pageSize }],
    async () => {
      const encodedSearchQuery = searchQuery ? encodeURIComponent(searchQuery) : '';

      const filterParams = createFilterParams(filters);

      const response = await fetch(
        `${APIBaseChronos}/api/case/${caseId}/facts?docId=${docId}&searchQuery=${encodedSearchQuery}&page=${page}&pageSize=${pageSize}&paginated=true&${filterParams}`,
        fetchConfigGET,
      );
      if (!response.ok) throw new Error('Network response was not ok');
      return response.json();
    },
    { refetchOnWindowFocus: false },
  );
}

export default useFactsData;
