import { useCallback, useEffect, useState } from 'react';

import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OrgMemberInfo, useAuthInfo } from '@propelauth/react';
import { trackCustomEvent } from 'analytics/Mixpanel';
import { ReactComponent as FileIcon } from 'assets/guide/homepage-guides/file-icon.svg';
import { ReactComponent as HammerAndWrenchIcon } from 'assets/guide/homepage-guides/hammer-and-wrench-icon.svg';
import { ReactComponent as MagnifyingGlassIcon } from 'assets/guide/homepage-guides/magnifying-glass-icon.svg';
import { ReactComponent as QuestionIcon } from 'assets/guide/homepage-guides/question-icon.svg';
import CustomModal from 'components/molecules/Modals/Custom';
import { GuideState, OrgUserWithMetadata } from 'types';

import { useCompleteGuide } from '../hooks/useUpdateCompletedGuides';

interface Guide {
  title: string;
  description: string;
  icon: React.ReactNode;
  value: string;
  status: GuideState;
}

// Guides before the complete/not complete status gets added by the backend data
const GUIDES_WITHOUT_STATUS = [
  {
    title: 'Viewing documents',
    description: 'Learn how to view documents in Wexler',
    icon: <FileIcon className="h-4 w-4" />,
    value: 'viewing-documents',
  },
  {
    title: 'Exploring facts',
    description: 'Learn how to view the facts Wexler has extracted',
    icon: <MagnifyingGlassIcon className="h-4 w-4" />,
    value: 'exploring-facts',
  },
  {
    title: 'Using Kim',
    description: 'Learn how to use our AI agent, Kim, to ask questions',
    icon: <QuestionIcon className="h-4 w-4" />,
    value: 'using-kim',
  },
  {
    title: 'Creating a case',
    description: 'Learn how to create your first case',
    icon: <HammerAndWrenchIcon className="h-4 w-4" />,
    value: 'creating-a-case',
  },
  {
    title: 'Creating a matter',
    description: 'Learn how to create your first matter',
    icon: <HammerAndWrenchIcon className="h-4 w-4" />,
    value: 'creating-a-matter',
  },
];

const MODAL_CONTENT: Record<string, { title: string; description: string; link: string }> = {
  'creating-a-matter': {
    title: 'Creating a matter',
    description: 'Watch the video below to learn how to create your first matter',
    link: 'https://www.loom.com/embed/87a9c58bf4d44719a6a7993eb6f44650',
  },
  'creating-a-case': {
    title: 'Creating a case',
    description: 'Watch the video below to learn how to create your first case',
    link: 'https://www.loom.com/embed/8ca41397b9594951a011b8527a4b8e95',
  },
  'using-kim': {
    title: 'Using Kim',
    description: 'Watch the video below to learn how to use our AI agent, Kim, to ask questions',
    link: 'https://www.loom.com/embed/a605d42c38d943099010bd7974d778bf',
  },
  'exploring-facts': {
    title: 'Exploring facts',
    description: 'Watch the video below to learn how to view the facts Wexler has extracted',
    link: 'https://www.loom.com/embed/d6fdc9d8f5ec4c12856225b01989c682',
  },
  'viewing-documents': {
    title: 'Viewing documents',
    description: 'Watch the video below to learn how to view documents in Wexler',
    link: 'https://www.loom.com/embed/81b2853f7790487ebc42657ef7b20b7e',
  },
};

const GuideModal = ({
  title,
  description,
  guideName,
  userId,
  link,
  state,
  onCompleteGuide,
}: {
  title: string;
  description: string;
  guideName: string;
  userId: string;
  link: string;
  state: GuideState;
  onCompleteGuide: () => void;
}) => {
  const { mutate: updateCompletedGuide } = useCompleteGuide();

  const markAsComplete = useCallback(
    () => updateCompletedGuide({ userId, guideName }),
    [userId, guideName, updateCompletedGuide],
  );

  useEffect(() => {
    if (state === GuideState.NOT_STARTED) {
      markAsComplete();
      onCompleteGuide();
    }
  }, [state, markAsComplete, onCompleteGuide]);

  return (
    <div className="p-6">
      <h2 className="mb-2 border border-x-0 border-t-0 pb-1 font-semibold">{title}</h2>
      <p className="text-sm text-gray-700">{description}</p>

      <div className="my-4 rounded-sm bg-gray-100" style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
        <iframe
          title={title}
          src={link + '?hideEmbedTopBar=true'}
          allowFullScreen
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
        ></iframe>
      </div>
    </div>
  );
};

const GuideItem = ({
  title,
  description,
  icon,
  onClick,
  status,
}: {
  title: string;
  description: string;
  icon: React.ReactNode;
  onClick: () => void;
  status: GuideState;
}) => {
  return status === GuideState.COMPLETED ? (
    <div
      onClick={onClick}
      className="flex w-full cursor-pointer items-center justify-between rounded-md border border-gray-300 bg-gray-100 p-2 text-xs text-gray-400 shadow transition-all duration-300 hover:bg-gray-200"
    >
      <div className="flex flex-row items-center gap-2">
        <p className="text-sm font-semibold">{title}</p>
        <span>{icon}</span>
      </div>
      <div className="flex flex-row items-center gap-2 text-green-600">
        <span>Viewed</span>
        <FontAwesomeIcon icon={faCircleCheck} />
      </div>
    </div>
  ) : (
    <div
      onClick={onClick}
      className="flex w-[46%] cursor-pointer flex-col rounded-md border shadow transition-all duration-300 hover:scale-105 hover:border-brandSecondary hover:border-opacity-50"
    >
      <div className="flex flex-row items-center gap-2 rounded-t border-b bg-gray-100 p-2">
        <p className="text-sm font-semibold">{title}</p>
        {icon}
      </div>

      <div className="p-4">
        <p className="text-xs">{description}</p>
        <div className="mt-4 flex w-full text-xs text-gray-400">Not Started</div>
      </div>
    </div>
  );
};

export const Guides = ({ user }: { user: OrgUserWithMetadata }) => {
  const [guides, setGuides] = useState<Guide[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<React.ReactNode | null>(null);
  const [completedGuides, setCompletedGuides] = useState<string[]>(user.completedGuides);
  const authInfo = useAuthInfo();
  const orgs = authInfo.orgHelper?.getOrgs();
  const asignedOrg = orgs?.length && (orgs[0] as OrgMemberInfo);
  const assignedRole = asignedOrg && asignedOrg?.userAssignedRole;
  const isAdmin = assignedRole === 'Admin';

  useEffect(() => {
    if (completedGuides) {
      // Set guide state based on if the guide name is in completed guides array
      const GUIDES_WITH_STATUS: Guide[] = GUIDES_WITHOUT_STATUS.map((guide) => ({
        ...guide,
        status: completedGuides.includes(guide.value) ? GuideState.COMPLETED : GuideState.NOT_STARTED,
      })).sort((a, _) => (a.status === GuideState.COMPLETED ? 1 : -1));

      setGuides(GUIDES_WITH_STATUS);
    }
  }, [completedGuides]);

  const handleOpenModal = (contentValue: string) => {
    setModalContent(
      <GuideModal
        title={MODAL_CONTENT[contentValue].title}
        description={MODAL_CONTENT[contentValue].description}
        guideName={contentValue}
        userId={user.id}
        link={MODAL_CONTENT[contentValue].link}
        state={completedGuides.includes(contentValue) ? GuideState.COMPLETED : GuideState.NOT_STARTED}
        onCompleteGuide={() => setCompletedGuides([...completedGuides, contentValue])}
      />,
    );
    setModalOpen(true);
  };

  return (
    <>
      {guides.length > 0 && (
        <div className="flex w-full flex-col gap-2">
          <h2 className="mb-2 border border-x-0 border-t-0 px-4 pb-1 font-semibold">Guides</h2>
          <div className="flex w-full flex-wrap justify-between gap-4 px-4 pb-4">
            {guides.map((guide) => {
              if (guide.value === 'creating-a-matter' && !isAdmin) return null;
              return (
                <GuideItem
                  key={guide.title}
                  title={guide.title}
                  description={guide.description}
                  icon={guide.icon}
                  onClick={() => {
                    trackCustomEvent(`Click Guide on homepage: ${guide.title}`, { type: guide.value });
                    handleOpenModal(guide.value);
                  }}
                  status={guide.status}
                />
              );
            })}
          </div>

          <CustomModal
            isOpen={modalOpen}
            handleClose={() => setModalOpen(false)}
            content={modalContent}
            maxWidth="52rem"
          />
        </div>
      )}
    </>
  );
};
