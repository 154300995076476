import React, { ChangeEvent, useState } from 'react';

import { useUpload } from '../utils/useUpload';

const SaveFileForm = ({ file, folderId, appId, consumerId, serviceId, jwt, onSuccess }: any) => {
  const [fileName, setFileName] = useState(file.name);
  const { uploadFile, isLoading } = useUpload({ onSuccess });

  return (
    <div className="absolute bottom-0 left-0 right-0 border-t bg-white" data-testid="file-to-save-form">
      <div className="flex items-center justify-center p-4">
        <input
          className="focus:shadow-outline w-full appearance-none rounded border border-gray-200 px-3 py-2 text-sm leading-tight text-gray-700 shadow-sm focus:outline-none"
          name="fileName"
          placeholder="File name"
          autoComplete="off"
          onChange={(event: ChangeEvent<HTMLInputElement>) => setFileName(event.target.value)}
          value={fileName}
        />
        <button
          type="button"
          className="ml-2 items-center whitespace-nowrap rounded-md border border-transparent bg-blue-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3"
          onClick={() =>
            uploadFile({
              file: { ...file, name: fileName },
              folderId,
              appId,
              consumerId,
              serviceId,
              jwt,
            })
          }
        >
          {isLoading ? 'Uploading...' : 'Save file'}
        </button>
      </div>
    </div>
  );
};

export default SaveFileForm;
