import { useState } from 'react';

import Button from 'components/atoms/Button';
import Modal from 'components/molecules/Modals/Settings';
import { allowedFileTypes } from 'constants/allowedFileTypes';
import { FileCheck } from 'lucide-react';

const PermittedFilesChip = () => {
  const [fileTypeModalOpen, setFileTypeModalOpen] = useState(false);
  return (
    <>
      <div
        onClick={() => setFileTypeModalOpen(true)}
        className="flex items-center gap-2 rounded-full border bg-gray-100 px-2 py-1 hover:cursor-pointer hover:bg-gray-200"
      >
        <FileCheck className="text-gray-700" size={14} />
        <span className="text-xs text-gray-700">Permitted Files</span>
      </div>

      <Modal
        title={
          <div className="flex items-center gap-2 text-lg font-semibold text-gray-900">
            <FileCheck className="text-gray-700" />
            Permitted file types
          </div>
        }
        size="small"
        isOpen={fileTypeModalOpen}
        handleClose={() => setFileTypeModalOpen(false)}
        content={
          <div className="flex flex-col gap-2 p-6">
            <p className="text-gray-700">Wexler supports the following file types:</p>
            <div className="flex flex-wrap gap-x-4 gap-y-2 rounded-md border bg-gray-50 p-2">
              {allowedFileTypes.map((fileType) => (
                <div className="rounded-md border bg-gray-100 px-2 text-xs font-medium" key={fileType}>
                  {fileType}
                </div>
              ))}
            </div>
            <ul className="flex list-inside list-disc flex-col gap-2 py-2 text-gray-700">
              <li className="text-sm">The maximum file size supported is 150MB</li>
              <li className="text-sm">The maximum number of files supported is 10,000</li>
            </ul>

            <p className="text-xs text-gray-700">
              Reach out to the Wexler team if these limits are not sufficient for your case.
            </p>
            <div className="mt-2 flex">
              <Button text="Close" type="delete" rounded="md" onClick={() => setFileTypeModalOpen(false)} />
            </div>
          </div>
        }
      />
    </>
  );
};

export default PermittedFilesChip;
