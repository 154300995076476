import { APIBaseChronos } from 'api/hosts';
import { QueryEntity } from 'api/types';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';
import { RecentActivityItem } from 'types';

const useGetRecentActivity = (userId: string) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<RecentActivityItem[]>(
    [QueryEntity.RecentUserActivity],
    () => {
      const url = new URL(`${APIBaseChronos}/api/user/${userId}/recent-activity`);

      return fetch(url.toString(), {
        ...fetchConfigGET,
        method: 'GET',
      })
        .then((res) => res.json())
        .catch((err) => {
          console.error(err);
        });
    },
    {
      enabled: !!userId,
      refetchOnWindowFocus: false,
    },
  );
};

export default useGetRecentActivity;
