import { APIBaseChronos } from 'api/hosts';
import { QueryEntity } from 'api/types';
import { GetChronologiesRes } from 'api/types/queries';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';

export const useGetChronologies = ({
  caseId,
  searchQuery,
  page,
  pageSize,
  paginated = true,
}: {
  caseId: string;
  searchQuery?: string;
  page?: number;
  pageSize?: number;
  paginated?: boolean;
}) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<GetChronologiesRes>(
    [QueryEntity.Chronologies, { caseId }, { searchQuery, page, pageSize, paginated }],
    () => {
      const cleanSearchText = searchQuery ? searchQuery?.replace(/\s+/g, ' ').trim() : '';
      return fetch(
        `${APIBaseChronos}/api/case/${caseId}/chronology/?search=${cleanSearchText}&page=${page}&pageSize=${pageSize}&paginated=${paginated}`,
        fetchConfigGET,
      ).then((res) => {
        return res.json();
      });
    },
  );
};
