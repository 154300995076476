import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useGetUser from 'api/queries/useGetUser';

import Actions from './components/Actions';
import GetStarted from './components/GetStarted';
import RecentActivity from './components/RecentActivity';
import { Guides } from './components/UserGuides';

const ActivityPanel = () => {
  const { data: userData, isLoading, isFetching } = useGetUser();

  return (
    <div className="w-1/3 py-4">
      {isLoading || isFetching || !userData ? (
        <div className="flex h-full items-center justify-center">
          <FontAwesomeIcon icon={faCircleNotch} className="animate-spin" />
        </div>
      ) : userData?.hasBeenActive ? (
        <>
          <Actions userId={userData.id} />
          <RecentActivity userId={userData.id} />
          <Guides user={userData} />
        </>
      ) : (
        <GetStarted user={userData} />
      )}
    </div>
  );
};

export default ActivityPanel;
