import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useUpdateChronologyFact from 'api/mutations/useUpdateChronologyFact';
import TooltipIcon from 'components/atoms/TooltipIcon';
import CommentPopup from 'components/molecules/Comments';
import EditableField from 'components/molecules/EditableField';
import { PlainTextContent, renderHighlightedTextWithLinks } from 'helpers/textRendering';
import { ChronologyFact, Location } from 'types';

import { useDocumentNavigation } from '../../../FactsEditor/utils/navigationUtils';

interface ChronologyDetailProps {
  caseId: string;
  fact: ChronologyFact;
  significanceText: string;
  handleOpenDeleteModal: (lineId: string) => void;
}

const ChronologyDetails = ({ caseId, fact, significanceText, handleOpenDeleteModal }: ChronologyDetailProps) => {
  const uploadDate = new Date(fact.createdDate);
  const { goToDocReference } = useDocumentNavigation();
  const { mutate: updateChronologyFact, isLoading: isUpdatingChronologyFact } = useUpdateChronologyFact({
    caseId: caseId,
    analyticsLocation: 'Chronology',
  });

  const handleUpdateFactText = (value: string) => {
    updateChronologyFact({
      field: 'significance',
      value,
      factId: fact.id,
      chronologyId: fact.chronologyId,
    });
  };

  return (
    <div className="text-xs">
      <div className="mt-2 border border-x-0 border-t-0 border-dashed border-blue-200"></div>

      <div className="flex gap-4 py-4 pl-2 pr-4">
        {/* Significance Section */}
        <div className="w-full">
          <div className="mb-1 flex items-center gap-1 px-2 font-semibold text-gray-800">
            Significance
            <TooltipIcon
              tooltipId={`significance-tooltip-${fact.id}`}
              tooltipContent="A short analysis of the significance of the fact as it pertains to the key case issues and the context of the document it is extracted from."
              className="mr-2 h-4 w-4 cursor-pointer"
            />
          </div>
          <div className="px-2 text-gray-800">
            <EditableField
              value={significanceText}
              onSave={handleUpdateFactText}
              isLoading={isUpdatingChronologyFact}
            />
          </div>
        </div>
      </div>

      <div className="border border-x-0 border-t-0 border-dashed border-blue-200"></div>

      <div className="py-4 pl-2 pr-4">
        {/* Documents and Source Text Section */}
        <div className="mb-1 px-2 font-semibold text-gray-800">Documents</div>
        <div>
          {fact.locations.map((location: Location) => {
            const document = location.doc;
            if (!document) return null;
            const pageNumber = location.boundingBoxes?.[0]?.pageNumber || 1;
            const file = document.file.name.split('.')[0] ?? document.file.name;
            return (
              <div
                key={file}
                className="flex items-baseline justify-between gap-4 border border-x-0 border-t-0 border-dashed px-2 pb-1"
              >
                <div className="flex items-baseline gap-4">
                  <div
                    key={document.id}
                    className="cursor-pointer overflow-hidden text-ellipsis text-blue-500 hover:text-blue-600 hover:underline"
                    onClick={() => goToDocReference(document.id, fact.fact.id, pageNumber)}
                  >
                    {file}
                  </div>
                  <span className="rounded-sm bg-brandTertiary bg-opacity-30 px-2 text-gray-600">{document.type}</span>
                  {document.dateText ? (
                    <span className="text-xs text-gray-600">
                      (<span className="text-xs">{document.dateText})</span>
                    </span>
                  ) : null}
                </div>
                <div className="flex items-baseline gap-6">
                  <span className="text-xs italic text-gray-600">
                    (uploaded: {`${uploadDate.getDate()}/${uploadDate.getMonth() + 1}/${uploadDate.getFullYear()}`})
                  </span>
                </div>
              </div>
            );
          })}
        </div>

        {fact.fact.sourceText && (
          <div>
            <div className="mb-1 mt-3 px-2 font-semibold text-gray-800">Source Text</div>
            <div className="px-2 text-gray-500">
              <PlainTextContent html={fact.fact.sourceText} maxLength={600} />
            </div>
          </div>
        )}
      </div>
      <div className="border-2 border-x-0 border-t-0 border-blue-200"></div>
      {/* Action Buttons Section */}
      <div className="flex justify-between gap-10 rounded-b-lg bg-slate-100 px-4">
        <div>
          <div className="pb-1 pt-2 font-semibold">Comments</div>
          <div>
            {fact.comments && fact.comments.length > 0 ? (
              renderHighlightedTextWithLinks(fact.comments[0].content)
            ) : (
              <span className="font-light text-gray-500">No comments yet</span>
            )}
          </div>
        </div>
        <div className="flex items-center gap-2 py-3">
          <CommentPopup
            caseId={caseId}
            factId={fact.factId}
            commentsCount={fact.comments.length}
            comments={fact.comments}
          />
          <button
            onClick={() => handleOpenDeleteModal(fact.id)}
            className="relative flex h-full items-center gap-2 rounded border bg-gray-50 px-3 py-2 text-red-700 shadow hover:cursor-pointer hover:bg-gray-100 hover:shadow-none"
          >
            <FontAwesomeIcon icon={faTrash} />
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChronologyDetails;
