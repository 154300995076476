import { APIBaseChronos } from 'api/hosts';
import { QueryEntity } from 'api/types';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';
import { Fact } from 'types';

export const useFetchKeyFacts = (caseId: string | null | undefined) => {
  const { fetchConfigGET } = useGetFetchConfig();

  const { data, error, isLoading, isFetching, refetch } = useQuery<Fact[], Error>(
    [QueryEntity.KeyFacts, caseId],
    async () => {
      const response = await fetch(`${APIBaseChronos}/api/case/${caseId}/key-facts`, fetchConfigGET);
      if (response.status === 404) {
        return null;
      }
      if (!response.ok) throw new Error('Network response was not ok');
      return response.json();
    },
  );
  return { data, error, isLoading, isFetching, refetch };
};
