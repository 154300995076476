import { APIBaseChronos } from 'api/hosts'; // Ensure this path is correct
import useGetFetchConfig from 'api/useGetFetchConfig'; // Ensure this path is correct
import { useMutation } from 'react-query';
import { OrgUserOption } from 'types';

interface UserUpdateMatterProps {
  id: string;
  name: string;
  description: string;
  code: string;
  users: OrgUserOption[];
}

const useUpdateMatter = (onSuccess: () => void) => {
  const { getFetchConfig } = useGetFetchConfig();

  const updateMatter = async ({ id, name, description, code, users }: UserUpdateMatterProps) => {
    const fetchConfig = getFetchConfig({
      method: 'PATCH',
      data: {
        name,
        description,
        code,
        userIds: users.map((x) => x.id),
        id,
      },
    });

    const response = await fetch(`${APIBaseChronos}/api/matters/${id}`, fetchConfig);
    if (!response.ok) {
      throw new Error('Error updating matter');
    }
    return await response.json();
  };

  return useMutation({
    onSuccess,
    mutationFn: updateMatter,
  });
};

export default useUpdateMatter;
