import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useMutation } from 'react-query';

const useTransferCase = () => {
  const { getFetchConfig } = useGetFetchConfig();

  const transferCase = useMutation(async ({ caseId, matterId }: { caseId: string; matterId: string }) => {
    const fetchConfig = getFetchConfig({
      method: 'PATCH',
      data: { matterId },
    });
    const response = await fetch(`${APIBaseChronos}/api/case/${caseId}/transfer`, fetchConfig);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
  });

  return transferCase;
};

export default useTransferCase;
