import React, { useEffect, useState } from 'react';

import { faArrowLeft, faArrowRight, faPenToSquare, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useCreateMatter from 'api/mutations/useCreateMatter';
import useUpdateMatter from 'api/mutations/useUpdateMatter';
import useGetMatter from 'api/queries/useGetMatter';
import useOrgUsers from 'api/queries/useGetOrgUsers';
import Button from 'components/atoms/Button';
import { Input } from 'components/atoms/input';
import { Label } from 'components/atoms/label';
import { Textarea } from 'components/atoms/textarea';
import { useUserContext } from 'Contexts/User';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toast as sonnerToast } from 'sonner';
import { MyOptionType, OrgUserOption } from 'types';

import UserList from './components/UserList';
const MatterCreator = ({ isEditMode = false }: { isEditMode?: boolean }) => {
  const navigate = useNavigate();
  const { matterId } = useParams<{ matterId: string }>();
  const [matterName, setMatterName] = useState<string>('');
  const [matterDescription, setMatterDescription] = useState<string>('');
  const [matterCode, setMatterCode] = useState<string>('');
  const [selectedUsers, setSelectedUsers] = useState<OrgUserOption[]>([]);

  const { user } = useUserContext();

  const onSuccess = () => {
    if (isEditMode) {
      toast.success('Matter updated successfully');
    } else {
      toast.success('Matter created successfully');
    }
    handleClickBack();
  };

  const { data: orgUsers } = useOrgUsers();
  const { mutate: updateMatter, isLoading: isLoadingUpdate } = useUpdateMatter(onSuccess);
  const { mutate: createMatter, isLoading: isLoadingCreateMatter } = useCreateMatter(onSuccess);
  const { isLoadingMatter, responseMatterData, refetchMatterData } = useGetMatter(matterId || '');

  useEffect(() => {
    if (matterId) {
      refetchMatterData();
    }
  }, [matterId, refetchMatterData]);

  // Pre-fill form when data is fetched
  useEffect(() => {
    if (responseMatterData && isEditMode) {
      setMatterName(responseMatterData.name);
      setMatterDescription(responseMatterData.description);
      setMatterCode(responseMatterData.code);
      setSelectedUsers(
        responseMatterData.users.map((x) => {
          return {
            id: x.id,
            email: x.email,
          };
        }),
      );
    }
  }, [responseMatterData, isEditMode]);

  const handleSubmit = () => {
    if (isEditMode) {
      if (matterId === undefined) {
        return;
      }
      updateMatter({
        id: matterId || '',
        name: matterName,
        description: matterDescription,
        code: matterCode,
        users: selectedUsers,
      });
    } else {
      createMatter({
        name: matterName,
        description: matterDescription,
        code: matterCode,
        users: selectedUsers,
      });
    }
  };

  const handleClickBack = () => {
    navigate(`/app/chronos/matters?page=0`);
  };

  const handleRemoveUser = (userIds: string[]) => {
    const updatedUsers = selectedUsers.filter((user) => !userIds.includes(user.id));
    setSelectedUsers(updatedUsers);
  };

  const handleAddUser = (userToAdd: MyOptionType) => {
    const newSelectedUsers = [
      ...selectedUsers,
      {
        id: userToAdd?.value || '',
        email: userToAdd?.label || '',
      },
    ];
    setSelectedUsers(newSelectedUsers);
    sonnerToast(`${userToAdd?.label} added`, {
      duration: 1000,
    });
  };

  const canCreateMatter = matterName && matterDescription && selectedUsers;

  return (
    <div className="relative flex min-h-screen w-full flex-row gap-6 overflow-auto bg-gray-50">
      <div className="flex w-full flex-col items-start justify-start px-8 py-6">
        {!isLoadingMatter && (
          <>
            <div className="mb-4 flex w-full items-center justify-between">
              <div className="flex items-center">
                <Button
                  className="rounded-full px-2 py-1 hover:text-gray-600"
                  onClick={handleClickBack}
                  icon={<FontAwesomeIcon icon={faArrowLeft} className="h-4 w-4" />}
                  text=""
                />
                <h1 className="ml-2 text-xl font-bold text-gray-900">
                  {isEditMode ? 'Update Matter' : 'Create Matter'}
                </h1>
              </div>
            </div>

            <div className="flex h-full w-full flex-row gap-8">
              <div className="flex w-full flex-row gap-8">
                <div className="flex w-1/2 flex-col justify-between gap-2 rounded-xl border border-gray-200 bg-white p-6 shadow-sm">
                  <div>
                    <div className="mb-2 flex items-center justify-between border-b border-gray-100 pb-4">
                      <div className="flex items-center">
                        <FontAwesomeIcon icon={faPenToSquare} className="mr-3 h-4 w-4 text-gray-600" />
                        <h2 className="text-lg font-semibold text-gray-900">Details</h2>
                      </div>
                    </div>

                    <div className="flex flex-col gap-4">
                      <div>
                        <Label className="text-sm font-medium text-gray-700">Matter Name</Label>
                        <Input
                          className="rounded-lg border-gray-300"
                          value={matterName}
                          onChange={(e) => setMatterName(e.target.value)}
                          placeholder="Enter a name for your matter"
                        />
                      </div>
                      <div>
                        <Label className="text-sm font-medium text-gray-700">Matter Description</Label>
                        <Textarea
                          className="h-12 max-h-12 rounded-lg border-gray-300"
                          value={matterDescription}
                          onChange={(e) => setMatterDescription(e.target.value)}
                          placeholder="Enter a description for your matter"
                        />
                      </div>

                      <div>
                        <Label className="text-sm font-medium text-gray-700">Matter Code (Optional)</Label>
                        <Input
                          className="rounded-lg border-gray-300"
                          value={matterCode}
                          onChange={(e) => setMatterCode(e.target.value)}
                          placeholder="Enter a code for your matter"
                        />
                      </div>
                    </div>

                    <div className="mt-4 text-xs text-gray-600 lg:text-sm">
                      <ul className="list-disc space-y-1 pl-4">
                        <li>Matters are spaces used to group cases together.</li>
                        <li>Users with permission to view a matter can view all cases within that matter.</li>
                        <li>You can update these details at any time, including adding or removing team members.</li>
                      </ul>
                    </div>
                  </div>
                  <div>
                    <Button
                      type="primary"
                      rounded="md"
                      onClick={handleSubmit}
                      text={isEditMode ? 'Update Matter' : 'Create Matter'}
                      loading={isLoadingUpdate || isLoadingMatter || isLoadingCreateMatter}
                      disabled={!canCreateMatter || isLoadingUpdate || isLoadingMatter || isLoadingCreateMatter}
                      icon={<FontAwesomeIcon className="ml-2" icon={faArrowRight} />}
                      iconRight
                    />
                  </div>
                </div>

                <div className="w-1/2 rounded-xl border border-gray-200 bg-white p-6 shadow-sm">
                  <div className="mb-2 flex items-center justify-between border-b border-gray-100 pb-4">
                    <div className="flex items-center">
                      <FontAwesomeIcon icon={faUsers} className="mr-3 h-4 w-4 text-gray-600" />
                      <h2 className="text-lg font-semibold text-gray-900">Users ({selectedUsers.length})</h2>
                    </div>
                  </div>
                  <UserList
                    users={orgUsers ?? []}
                    selectedUsers={selectedUsers}
                    userId={user?.id ?? ''}
                    onRemove={handleRemoveUser}
                    onAdd={handleAddUser}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MatterCreator;
