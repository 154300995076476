import { APIBaseChronos } from 'api/hosts';
import { QueryEntity } from 'api/types';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';

import { GetSummaryRes } from '../types/queries';

const useGetSummary = (caseId: string | null = '') => {
  const { fetchConfigGET } = useGetFetchConfig();

  const getSummary = async (): Promise<GetSummaryRes> => {
    const response = await fetch(`${APIBaseChronos}/api/case/${caseId}/summary`, fetchConfigGET);
    if (!response.ok) {
      throw new Error('Fetching messages failed');
    }

    if (response.headers.get('content-type')?.includes('application/json')) {
      return response.json();
    }
  };

  return useQuery([QueryEntity.Summary, caseId], getSummary);
};

export default useGetSummary;
