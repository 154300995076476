import { faExternalLinkSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { trackCustomEvent } from 'analytics/Mixpanel';
import { motion } from 'framer-motion';
import formatDate from 'helpers/formatDate';
import { useNavigate } from 'react-router-dom';
import { RecentActivityItem } from 'types';

import useGetRecentActivity from '../../hooks/useGetRecentActivity';

const getRecentActivityDescription = (type: RecentActivityItem['type']) => {
  switch (type) {
    case 'thread':
      return 'Asked a Kim question';
    case 'chronology':
      return 'Created a chronology';
    case 'run':
      return 'Processed documents';
  }
};

const RecentActivityRow = ({
  title,
  description,
  link,
  createdDate,
}: {
  title: string;
  description: string;
  link: string;
  createdDate: string;
}) => {
  const navigate = useNavigate();

  return (
    <div
      className="group flex cursor-pointer items-center justify-between rounded bg-gray-200 px-2 py-1 text-gray-800 transition-all duration-150 hover:bg-gray-300"
      onClick={() => {
        trackCustomEvent('Click Recent Activity on homepage');
        navigate(link);
      }}
    >
      <div>
        <span className="font-semibold">{title}</span>
        <span>{': '}</span>
        <span className="text-gray-600">{description}</span>
      </div>
      <div className="flex items-center gap-2">
        <span className="text-gray-600">{formatDate(createdDate, true)}</span>
        <FontAwesomeIcon
          icon={faExternalLinkSquare}
          className="text-gray-600 transition-transform duration-150 group-hover:text-gray-900"
        />
      </div>
    </div>
  );
};

const RecentActivity = ({ userId }: { userId: string }) => {
  const { data: recentActivity } = useGetRecentActivity(userId);

  if (!recentActivity || recentActivity.length === 0) {
    return null;
  }

  return (
    <div className="mb-8 flex w-full flex-col gap-4">
      <h2 className="border border-x-0 border-t-0 px-4 pb-1 font-semibold">Recent</h2>
      <div className="flex w-full flex-col gap-2 px-4 text-xs">
        {recentActivity?.map((activity, index) => (
          <motion.div
            key={index}
            className="text-gray-800"
            initial={{ opacity: 0, y: 40 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: index * 0.1 }}
          >
            <RecentActivityRow
              key={index}
              title={activity.caseName}
              description={getRecentActivityDescription(activity.type)}
              link={activity.link}
              createdDate={activity.createdDate}
            />
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default RecentActivity;
